import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Ads from "../../components/Ads";
import LineChart from "../../components/LineChart";
import axios from "axios";
import { ArrowDownward, ArrowUpward } from "@mui/icons-material";
import { toast } from "react-toastify";
import HistoricalDataTab from "../../components/HistoricalDataTab";
import HistoricDataNewsTab from "../../components/HistoricDataNewsTab";
import { generateToken } from "../../utils/generateWatchlistToken";
import BulkDealsTab from "../../components/BulkDealsTab";
import BseAnnouncements from "../../components/BseAnnouncements";

const StockInfo = ({ label, value }) => (
  <div className="flex w-full justify-between gap-2 border-b px-3 py-2">
    <p>{label}</p>
    <p>{value}</p>
  </div>
);

const HistoricDataPage = ({ setisLoggedIn, isLoggedIn, sideMenuCollapsed }) => {
  const { symbol, finCode } = useParams(); 
  const [selectedTab, setSelectedTab] = useState("1D");
  const [chartData, setChartData] = useState({
    timeLabels: [],
    priceData: [],
  });
  const [stockData, setstockData] = useState([]);
  const [mCap, setMCap] = useState(null);
  const [activeButton, setActiveButton] = useState("news");
  // const [watchlistToken, setWatchlistToken] = useState(null);

  const excode = "BE49H6S";
  const loggeInToken = JSON.parse(localStorage.getItem("WatchlistToken"))
  const tempToken = localStorage.getItem("TempToken")

  const watchlistToken = isLoggedIn ? loggeInToken : tempToken

  // useEffect(() => {
  //   // setTimeout(() => {
  //     if (isLoggedIn) {
  //       setWatchlistToken(JSON.parse(localStorage.getItem("WatchlistToken")));
  //     } else {
  //       setWatchlistToken(localStorage.getItem("TempToken"));
  //     }
  //   // }, 0);
  // }, [isLoggedIn]);

  const navigate = useNavigate();

  const fetchHistoricData = async (tab) => {
    let dateOption = "";
    let dateCount = "";

    switch (tab) {
      case "1D":
        dateOption = "I"; // Intraday
        dateCount = "1";
        break;
      case "5D":
        dateOption = "D"; // 5 days
        dateCount = "5";
        break;
      case "1M":
        dateOption = "M"; // 1 month
        dateCount = "1";
        break;
      case "6M":
        dateOption = "M"; // 6 months
        dateCount = "6";
        break;
      case "1Y":
        dateOption = "Y"; // 1 year
        dateCount = "1";
        break;
      case "5Y":
        dateOption = "Y"; // 5 years
        dateCount = "5";
        break;
      default:
        break;
    }

    try {
      const response = await axios.get(
        `https://stock.accordwebservices.com/BSEStock/GetChartData?ExCode=BE49H6S&Type=${
          dateOption == "I" ? "I" : "H"
        }&FINCODE=${finCode}&DateOption=${dateOption}&DateCount=${dateCount}&StartDate=&EndDate=&Token=${watchlistToken}`
      );

      const formattedTimeLabels = response?.data?.Table?.map((item) => {
        const date = new Date(item.Date);

        if (selectedTab === "1D") {
          return date.toLocaleTimeString([], {
            hour: "2-digit",
            minute: "2-digit",
          });
        } else {
          // Show month date and year
          const month = new Intl.DateTimeFormat("en-US", {
            month: "short",
          }).format(date);
          const day = date.getDate();
          const year = date.getFullYear();
          return `${month} ${day} ${year}`;
        }
      });
      // Process the response and update state with the new data
      const priceData = response.data.Table.map((item) =>
        parseFloat(item.price)
      );

      setChartData({
        timeLabels: formattedTimeLabels,
        priceData: priceData,
      });
    } catch (error) {
      if (error.code == "ERR_NETWORK" && isLoggedIn) {
        console.log("Came here::", error);
        localStorage.clear();
        setisLoggedIn(false);
        navigate("/");
        toast.error("Session Expired, please login again");
      }
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    const currentTime = new Date().getHours();
    const isWeekend = new Date().getDay() === 6 || new Date().getDay() === 0;
    const isMarketHours = currentTime >= 9 && currentTime <= 15 && !isWeekend;

    const fetchData = async () => {
      await fetchHistoricData(selectedTab);
      await fetchStockData();
    };
    fetchData();

    if (isMarketHours && selectedTab === "1D") {
      fetchData();
      const interval = setInterval(fetchData, 60000);

      return () => clearInterval(interval);
    }
  }, [finCode]);

  const fetchStockData = async () => {
    const apiUrl = `https://stock.accordwebservices.com/BSEStock/GetQuoteFinder?fincode=${finCode}&token=${watchlistToken}`;
    try {
      const response = await axios.get(apiUrl);
      setstockData(response.data.Table[0]);
    } catch (error) {
      if (error.code == "ERR_NETWORK" && isLoggedIn) {
        console.log("Came here::", error);
        // generateToken(fetchStockData);
      }
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    fetchStockData();
  }, [finCode]);

  const handleTabChange = async (tab) => {
    setSelectedTab(tab);
    await fetchHistoricData(tab);
  };

  const tabs = [
    { label: "1D", value: "1D" },
    { label: "5D", value: "5D" },
    { label: "1M", value: "1M" },
    { label: "6M", value: "6M" },
    { label: "1Y", value: "1Y" },
    { label: "5Y", value: "5Y" },
  ];

  return (
    <div className={`flex justify-between w-full mt-16 md:mt-2 ${sideMenuCollapsed ? "md:w-full" : "md:ml-28"} transition-all duration-500`}>
      <div className="bg-white w-[100%] rounded-lg px-2 py-4 md:p-4 mt-2">
        <h1 className="text-3xl font-semibold">{stockData?.S_NAME}</h1>

        <hr className="mt-2"></hr>
        <div className="flex items-center gap-2 mt-3">
          <h1 className="font-semibold text-lg">{stockData?.CLOSE}</h1>
          <div
            className={`px-2 py-1 rounded font-semibold flex items-center ${
              stockData?.PER_CHANGE && parseFloat(stockData.PER_CHANGE) < 0
                ? "bg-errorLight text-error"
                : "bg-successLight text-success"
            }`}
          >
            {stockData?.PER_CHANGE && parseFloat(stockData.PER_CHANGE) < 0 ? (
              <ArrowDownward />
            ) : (
              <ArrowUpward />
            )}
             {Math.abs(stockData?.PER_CHANGE)}%
          </div>
          <p
            className={`${
              stockData?.CHANGE < 0 ? "text-error" : "text-success"
            }`}
          >
            {stockData?.CHANGE < 0 ? "-" : "+"}
            {Math.abs(stockData?.CHANGE)} Today
          </p>
        </div>
        <p className="text-muted">{stockData?.UPDTIME}</p>
        <div className="flex my-3">
          {tabs.map((tab) => (
            <div key={tab.value} className="px-2 border-r">
              <button
                className={` ${
                  selectedTab === tab.value
                    ? "border-b-2 border-b-primaryBrown text-primaryBrown"
                    : ""
                }`}
                onClick={() => handleTabChange(tab.value)}
              >
                {tab.label}
              </button>
            </div>
          ))}
        </div>
        <div className=" flex justify-between gap-4 flex-wrap md:!flex-nowrap  mt-3">
          <div className=" w-full ">
            <LineChart
              timeLabels={chartData.timeLabels}
              priceData={chartData.priceData}
              isNegative={
                stockData?.PER_CHANGE && parseFloat(stockData.PER_CHANGE) < 0
              }
            />
          </div>
          <div className="rounded-xl  w-full  overflow-y-auto border flex flex-col justify-between shadow-sm">
            {Object.keys(stockData).length > 0 && (
              <>
                <StockInfo label="PREV CLOSE" value={stockData?.PREV_CLOSE} />
                <StockInfo
                  label="Day Range"
                  value={`${stockData?.HIGH}-${stockData?.LOW}`}
                />
                <StockInfo
                  label="Year Range"
                  value={`${stockData["52WEEKLOW"]}-${stockData["52WEEKHIGH"]}`}
                />
                <StockInfo label="Market Cap" value={mCap} />
                {/* <StockInfo label="Revenue" value="-" /> */}
                <StockInfo label="Open" value={stockData?.OPEN} />
                <StockInfo label="Volume" value={stockData?.VOLUME} />
              </>
            )}
          </div>
        </div>
        <div className="mt-4">
          <button
            className={`w-32  py-2 rounded-md ${
              activeButton === "news"
                ? " bg-secondaryHovered text-primaryBrown shadow-md z-10"
                : "bg-secondaryBtn text-black rounded-r-none"
            }`}
            onClick={() => setActiveButton("news")}
          >
            News
          </button>
          <button
            className={`w-32  py-2 rounded-md ${
              activeButton === "historical"
                ? " bg-secondaryHovered text-primaryBrown shadow-md z-10"
                : "bg-secondaryBtn text-black rounded-l-none"
            }`}
            onClick={() => setActiveButton("historical")}
          >
            Historical Data
          </button>
          <button
            className={`w-32 py-2 rounded-md ${
              activeButton === "bulk_deals"
                ? " bg-secondaryHovered text-primaryBrown shadow-md z-10"
                : "bg-secondaryBtn text-black rounded-l-none"
            }`}
            onClick={() => setActiveButton("bulk_deals")}
          >
            Bulk Deals
          </button>
          <button
            className={`w-32  py-2 rounded-md ${
              activeButton === "block_deals"
                ? " bg-secondaryHovered text-primaryBrown shadow-md z-10"
                : "bg-secondaryBtn text-black rounded-l-none"
            }`}
            onClick={() => setActiveButton("block_deals")}
          >
            Block Deals
          </button>
          <button
            className={`px-2  py-2 rounded-md ${
              activeButton === "bse_announcements"
                ? " bg-secondaryHovered text-primaryBrown shadow-md z-10"
                : "bg-secondaryBtn text-black rounded-l-none"
            }`}
            onClick={() => setActiveButton("bse_announcements")}
          >
            BSE Announcements
          </button>
          
        </div>
        <div className="w-full">
          {activeButton === "historical" ? (
            <HistoricalDataTab
              selectedTab={selectedTab}
              excode={excode}
              finCode={finCode}
              watchlistToken={watchlistToken}
            />
          ) : (
            activeButton === "news" 
            ? <HistoricDataNewsTab symbol={symbol} setMCap={setMCap} />
            :  activeButton === "bulk_deals" 
            ? <BulkDealsTab type={"bulk"} symbol={symbol}/>
            : activeButton === "block_deals" 
            ? <BulkDealsTab type={"block"} symbol={symbol}/>
            : <BseAnnouncements finCode={finCode} token={watchlistToken}/>
          )}
        </div>
      </div>

      {/* Ads component */}
      <div className="hidden md:block">
        <Ads />
      </div>
    </div>
  );
};

export default HistoricDataPage;
