import { Card, IconButton, Modal, Tooltip } from "@mui/material";
import React, { useState, useRef, useCallback } from "react";
import PlusIcon from "../../components/Icons/PlusIcon";
import NewsCard from "../../components/NewsCard/NewsCard";
import axios from "axios";
import Loader from "../../components/Loader/Loader";
import { ArrowDownward, ArrowUpward, CheckCircle } from "@mui/icons-material";
import { toast } from "react-toastify";

const apiHost = process.env.REACT_APP_BACKEND_URL;

const StockCard = ({ data }) => {
  const [news, setNews] = useState([]);
  const [loading, setLoading] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [page, setPage] = useState(1);
  const [hasMore, setHasMore] = useState(true);
  const [inWatchlist, setInWatchlist] = useState(data.inWatchlist);

  // console.log(data)

  const observer = useRef(null);
  const lastNewsElementRef = useRef(null);

  const handleModalOpen = () => {
    setIsModalOpen(true);
    setPage(1);
    setNews([]);
    fetchNewsForModal(1);
  };

  const handleModalClose = () => {
    setIsModalOpen(false);
  };

  const fetchNewsForModal = async (pageNumber) => {
    setLoading(true);
    try {
      const url = `/api/news-feed?tag=${data?.symbol}&page=${pageNumber}&page_size=7`;
      const response = await axios.get(process.env.REACT_APP_BACKEND_URL + url);
      const newNews = response.data.results.news;
      setNews((prevNews) => [...prevNews, ...newNews]);
      setPage(pageNumber + 1);
      setHasMore(newNews.length > 0);
    } catch (error) {
      console.log("Error", error);
      setHasMore(false);
      setLoading(false);
    } finally {
      setLoading(false);
    }
  };

  const lastNewsCallback = useCallback(
    (node) => {
      if (loading) return;

      if (observer.current) observer.current.disconnect();

      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting && hasMore) {
          fetchNewsForModal(page);
        }
      });

      if (node) observer.current.observe(node);
    },
    [loading, page, hasMore]
  );

  const handleAddToWatchlist = () => {
    const token = localStorage.getItem("Token");
    if (!token) {
      toast.error("You need to be logged in to perform this action");
      return;
    }
    try {
      const config = {
        symbols: [data.symbol], // Wrap the symbol in an array
      };
      axios
        .post(apiHost + "/api/watchlist/", config, {
          headers: {
            Authorization: `Bearer ${JSON.parse(
              localStorage.getItem("Token")
            )}`,
          },
        })
        .then((response) => {
          toast.success("Successfully added to your watchlist!");
          setInWatchlist(true);
        })
        .catch((error) => {
          console.log("Error", error);
          toast.error("Failed to add to your watchlist.");
        });
    } catch (error) {
      console.log("Error", error);
    }
  };

  const removeHtmlTags = (text) => {
    return text.replace(/<[^>]+>/g, ""); // Removes all HTML tags
  };

  const sanitizedText = removeHtmlTags(data?.news);

  return (
    <>
      <Card
        sx={{
          borderRadius: "10px",
          width: 220,
          height: 190,
        }}
        className="stock-card pt-2 border border-1 shadow-sm"
      >
        <a href={data?.link} target="_blank">
          <h3 className="font-semibold text-sm h-[40px] px-3">{data?.title}</h3>
        </a>
        <p className="mt-3 text-xs font-semibold px-3">{data?.price}</p>
        <div className="flex justify-between items-center px-3">
          <div
            className={`${
              data?.profit
                ? "bg-green-100 text-green-700"
                : "bg-red-100 text-red-700"
            } mt-2 p-1 px-2 rounded text-xs font-semibold flex items-center gap-1`}
          >
            {data?.profit ? (
              <ArrowUpward color="currentColor" fontSize="20px" />
            ) : (
              <ArrowDownward color="currentColor" fontSize="18px" />
            )}
            {data?.profit ? data?.profit : data?.loss}%
          </div>
          {inWatchlist ? (
            <>
              <Tooltip title="Present in your watchlist">
                <IconButton>
                  <CheckCircle color="success" />
                </IconButton>
              </Tooltip>
            </>
          ) : (
            <Tooltip title="Add to watchlist">
              <IconButton onClick={handleAddToWatchlist}>
                <PlusIcon />
              </IconButton>
            </Tooltip>
          )}
        </div>
        <Tooltip title="Click to see similar news" arrow>
          <p
            className="text-xs mt-3 h-12 font-black cursor-pointer bg-primaryPurple p-2 ps-3  rounded-t-none shadow"
            onClick={handleModalOpen}
          >
            {sanitizedText.length > 60
              ? `${sanitizedText.slice(0, 55)}...`
              : sanitizedText}
          </p>
        </Tooltip>
      </Card>

      <Modal
        open={isModalOpen}
        onClose={handleModalClose}
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <div className="bg-white max-h-[500px] w-[450px] rounded-3xl flex flex-col">
          <div className="flex justify-between items-center p-2 px-3 py-3">
            <span className="font-semibold px-2 text-xl">Latest News</span>
            <div
              onClick={handleModalClose}
              className="h-10 pt-2 font-semibold cursor-pointer pr-2"
            >
              X
            </div>
          </div>
          <hr />
          <div className="overflow-y-auto pt-2">
            {news.map((newsItem, index) => {
              if (news.length === index + 1) {
                return (
                  <div
                    ref={lastNewsCallback}
                    key={index}
                    className="flex items-center justify-center flex-wrap "
                  >
                    <NewsCard
                      data={newsItem}
                      needGap
                      cardHeight={110}
                      percentage={false}
                    />
                  </div>
                );
              } else {
                return (
                  <div
                    key={index}
                    className="flex items-center justify-center flex-wrap"
                  >
                    <NewsCard
                      data={newsItem}
                      cardHeight={110}
                      percentage={false}
                    />
                  </div>
                );
              }
            })}
            {loading && <Loader />}
          </div>
          <hr />
          <div className="flex justify-end gap-2 pr-4 py-4 items-center">
            <button
              className="btn rounded-full font-semibold text-primaryBtn bg-secondaryBtn hover:bg-primaryHovered hover:text-white  px-4 py-2 border-primaryBtn "
              onClick={handleModalClose}
            >
              Close
            </button>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default StockCard;
