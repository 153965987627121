import React, { useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import axios from "axios";
import SearchIcon from "@mui/icons-material/Search";
import CloseIcon from "@mui/icons-material/Close";
import BSELogo from "../assets/image/BSELogo.png";
import HistoricDataNewsPortal from "../pages/HistoricData/HistoricDataNewsPortal";
import { Link, useLocation, useNavigate } from "react-router-dom";

const apiHost = process.env.REACT_APP_BACKEND_URL;

export default function MarketSearchNewsPortal(
  isLoggedIn,
  setLogin,
  rowFincode,
  rowSymbol
) {
  const navigate = useNavigate();
  const [searchTerm, setSearchTerm] = useState("");
  const [stockSymbols, setStockSymbols] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [inputFocused, setInputFocused] = useState(false);
  const [inputHovered, setInputHovered] = useState(false);
  const [indices, setIndices] = useState([{}]);
  const [advDeclData, setAdvDeclData] = useState([]); // State to store advances and declines data
  const [indicesData, setIndicesData] = useState([]); // State for Indices data
  const [sectorsData, setSectorsData] = useState([]); // State for Sectors data

  const [selectedFincode, setSelectedFincode] = useState(null);
  const [selectedSymbol, setSelectedSymbol] = useState(null);

  const filteredDataRef = useRef(null);
  const searchRef = useRef(null);

  const tempToken = localStorage.getItem("TempToken");

  let setisLoggedIn = setLogin;

  useEffect(() => {
    fetchStockSymbols();
    fetchIndices();
    fetchAdvancesAndDeclines(); // Fetching advances and declines data
    if (!isLoggedIn) {
      fetchLoggedoutToken();
    }
  }, [isLoggedIn]);

  const fetchLoggedoutToken = async () => {
    try {
      const response = await axios.get(`${apiHost}/api/get-client-token`);
      const data = response?.data?.token;
      localStorage.setItem("TempToken", data);
      fetchAdvancesAndDeclines(); // Ensure to fetch advances and declines when token is updated
    } catch (error) {
      console.log("An error ocurred", error);
    }
  };

  const fetchAdvancesAndDeclines = async () => {
    const url = `https://stock.accordwebservices.com/BSEStock/GetAdvancesnDeclines?ExCode=BE49H6S&Group=&Top=&PageNo=1&Pagesize=10&SortExpression=ADV&SortDirection=Desc&token=${tempToken}`;
    try {
      const response = await axios.get(url);
      const jsonData = response.data;
      if (jsonData) {
        if (jsonData.Table) {
          setAdvDeclData(jsonData.Table);
        }
        if (jsonData.Table2) {
          // console.log("Table2", jsonData.Table2);
          setIndicesData(jsonData.Table2); // Setting data for Table2 (Indices)
        }
        if (jsonData.Table3) {
          // console.log("Table3", jsonData.Table3);
          setSectorsData(jsonData.Table3); // Setting data for Table3 (Sectors)
        }
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      const isSearchInputFocused = document.activeElement === searchRef.current;
      const isInputEmpty = !searchTerm;

      if (
        (!isSearchInputFocused && filteredDataRef.current) ||
        (isInputEmpty && searchRef.current)
      ) {
        if (
          (!searchRef.current || !searchRef.current.contains(event.target)) &&
          (!filteredDataRef.current ||
            !filteredDataRef.current.contains(event.target))
        ) {
          setInputFocused(false);
        }
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const fetchStockSymbols = async () => {
    try {
      const response = await axios.get(`${apiHost}/api/stock-symbols`);
      if (response.data) {
        setStockSymbols(response.data);
      }
    } catch (error) {
      console.error("Error fetching stock symbols:", error);
    }
  };

  const fetchIndices = async () => {
    try {
      const watchlistToken =
        tempToken || JSON.parse(localStorage.getItem("WatchlistToken"));
      const apiURL = `https://stock.accordwebservices.com/BSEStock/GetLiveIndiceData?ExCode=BE49H6S&Top=1&PageNo=1&Pagesize=100&SortExpression=&SortDirection=Asc&token=${watchlistToken}`;
      const response = await axios.get(apiURL);
      if (response.data) {
        setIndices(response.data.Table);
      }
    } catch (error) {
      console.error("Error fetching indices:", error);
    }
  };

  const handleSearchChange = (event) => {
    const searchTerm = event.target.value.toLowerCase();

    // Filtering based on both symbol and name
    const filteredResults = stockSymbols.filter(
      (stock) =>
        stock.symbol.toLowerCase().startsWith(searchTerm) ||
        stock.name.toLowerCase().startsWith(searchTerm)
    );

    setSearchTerm(event.target.value);
    setFilteredData(filteredResults);
  };

  useEffect(() => {
    // Check if the token has expired when the component mounts
    const token = localStorage.getItem("Token");
    if (token) {
      const decodedToken = parseJwt(token);
      if (decodedToken.exp && decodedToken.exp * 1000 < Date.now()) {
        // Token has expired, log the user out
        localStorage.clear();
        setisLoggedIn(false);
      }
    }
  }, [setisLoggedIn]);

  const handleSymbolClick = (symbol) => {
    const selectedStock = stockSymbols.find((stock) => stock.symbol === symbol);
    if (selectedStock) {
      setSelectedFincode(selectedStock.fincode); // Set the selected fincode
      setSelectedSymbol(selectedStock.symbol); // Set the selected symbol
      setSearchTerm("");
      setInputFocused(false);
    }
  };

  const parseJwt = (token) => {
    try {
      return JSON.parse(atob(token.split(".")[1]));
    } catch (e) {
      return null;
    }
  };

  // const renderProgressBar = (adv, dec, name) => {
  //   const total = adv + dec;
  //   const advPercent = Math.max((adv / total) * 100, 5); // Ensure minimum width of 5%
  //   const decPercent = Math.max((dec / total) * 100, 5); // Ensure minimum width of 5%

  //   // Adjust percentages to add up to 100% if either is adjusted above
  //   const totalVisualPercent = advPercent + decPercent;
  //   const adjustedAdvPercent = (advPercent / totalVisualPercent) * 100;
  //   const adjustedDecPercent = (decPercent / totalVisualPercent) * 100;

  //   return (
  //     <div className="flex flex-col items-center my-4">
  //       <div className="flex items-center w-full">
  //         <div className="w-32 text-sm mr-2">{name}</div>
  //         <div className="flex-grow relative rounded overflow-hidden">
  //           <div className="flex h-3 rounded">
  //             <div
  //               style={{ width: `${adjustedAdvPercent}%` }}
  //               className="bg-[#198753] h-full rounded-l"
  //             ></div>
  //             <div
  //               style={{ width: `${adjustedDecPercent}%` }}
  //               className="bg-red-500 h-full ml-0.5 rounded-r"
  //             ></div>
  //           </div>
  //           <div className="flex w-full px-2 text-xs font-medium">
  //             <div
  //               style={{ width: `${adjustedAdvPercent}%`, textAlign: "left" }}
  //             >
  //               <span className="text-green-800">{adv}</span>
  //             </div>
  //             <div
  //               style={{ width: `${adjustedDecPercent}%`, textAlign: "right" }}
  //             >
  //               <span className="text-red-800">{dec}</span>
  //             </div>
  //           </div>
  //         </div>
  //       </div>
  //     </div>
  //   );
  // };

  const renderProgressBar = (adv, dec, name) => {
    const total = adv + dec;
    let advPercent = (adv / total) * 100;
    let decPercent = (dec / total) * 100;

    // Handle edge cases for zero values
    if (total === 0) {
      advPercent = 50; // Arbitrarily set to 50% to visually represent no data
      decPercent = 50;
    }

    // Adjust percentages to add up to 100% if either is adjusted above
    const totalVisualPercent = advPercent + decPercent;
    if (totalVisualPercent !== 100) {
      advPercent = (advPercent / totalVisualPercent) * 100;
      decPercent = (decPercent / totalVisualPercent) * 100;
    }

    // Ensure minimum visual width of 5% for any non-zero value
    advPercent = adv > 0 ? Math.max(advPercent, 5) : 0;
    decPercent = dec > 0 ? Math.max(decPercent, 5) : 0;

    // Adjust if total of percentages exceeds 100% due to minimum width adjustment
    const adjustedTotalPercent = advPercent + decPercent;
    if (adjustedTotalPercent !== 100) {
      if (advPercent > decPercent) {
        decPercent = 100 - advPercent;
      } else {
        advPercent = 100 - decPercent;
      }
    }

    return (
      <div className="flex flex-col items-center my-4">
        <div className="flex items-center w-full">
          <div className="w-32 text-sm mr-2">{name}</div>
          <div className="flex-grow relative rounded overflow-hidden">
            <div className="flex h-3 rounded">
              <div
                style={{ width: `${advPercent}%` }}
                className="bg-[#198753] h-full rounded-l"
              ></div>
              <div
                style={{ width: `${decPercent}%` }}
                className="bg-red-500 h-full ml-0.5 rounded-r"
              ></div>
            </div>
            <div className="flex w-full px-2 text-xs font-medium">
              <div style={{ width: `${advPercent}%`, textAlign: "left" }}>
                <span className="text-green-800">{adv}</span>
              </div>
              <div style={{ width: `${decPercent}%`, textAlign: "right" }}>
                <span className="text-red-800">{dec}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };
  const location = useLocation();
  const [showAdvDeclData, setShowAdvDeclData] = useState(false);

  useEffect(() => {
    // Check if the state was passed to this component
    if (location.state?.showAdvDeclData) {
      setShowAdvDeclData(true);
    }
  }, [location]);

  return (
    <>
      {showAdvDeclData && (
        <div className="flex items-center py-0">
          <Link
            to="/news-portal/home"
            className="text-blue-500 hover:text-blue-700 font-semibold text-sm transition duration-200"
          >
            Market
          </Link>
          <span className="mx-2 text-gray-700 text-xs">&gt;</span>
          <span className="text-black text-sm">Advance / Decline</span>
        </div>
      )}
      <nav className="navbar navbar-expand-lg w-full bg-navbarBg h-[60px] fixed z-40 md:z-none md:relative">
        <div className="flex justify-between w-full md:mx-5 mt-1">
          <div className="navbar-collapse" id="navbarSupportedContent">
            <div className="w-full flex justify-start">
              <div className="relative max-w-[500px] flex-grow">
                <div
                  className={`absolute left-2 top-1 p-1 ${
                    inputHovered
                      ? inputFocused
                        ? ""
                        : "bg-searchPrimary bg-opacity-5 rounded-full"
                      : ""
                  }`}
                >
                  <SearchIcon className="text-primaryHovered" />
                </div>
                <input
                  ref={searchRef}
                  className={`ps-5 font-medium text-primaryHovered ${
                    inputFocused
                      ? searchTerm
                        ? "bg-searchPrimary bg-opacity-5 rounded-[20px] rounded-b-none shadow-md"
                        : "bg-white rounded-full shadow-md"
                      : inputHovered
                      ? "bg-white rounded-full"
                      : "bg-searchPrimary bg-opacity-5 rounded-full"
                  } placeholder:text-inherit outline-none pe-3 py-2 lg:w-full shadow-sm`}
                  type="text"
                  placeholder="Search"
                  value={searchTerm}
                  onChange={handleSearchChange}
                  onFocus={() => setInputFocused(true)}
                  onMouseOver={() => setInputHovered(true)}
                  onMouseLeave={() => setInputHovered(false)}
                />
                <div
                  className={`${
                    searchTerm
                      ? "absolute right-2 top-1 p-1 cursor-pointer"
                      : "hidden"
                  }`}
                  onClick={() => setSearchTerm("")}
                >
                  <CloseIcon />
                </div>
                {searchTerm && inputFocused && (
                  <div
                    ref={filteredDataRef}
                    className={`absolute bg-white top-10 ${
                      searchTerm && inputFocused ? "" : ""
                    } border-t border-searchPrimary shadow-lg w-full z-50 h-72 rounded-b-[20px] overflow-y-scroll scrollbar-thin scrollbar-thumb-gray-300`}
                  >
                    {filteredData.length === 0 ? (
                      <div className="py-3 text-center flex items-center justify-center h-full">
                        No data found
                      </div>
                    ) : (
                      filteredData.map((data, index) => (
                        <div
                          className="py-3 pl-2 flex items-center gap-4 overflow-hidden border-gray-300 cursor-pointer transition-transform transform hover:bg-secondaryHovered"
                          key={index}
                          onClick={() => handleSymbolClick(data?.symbol)}
                        >
                          <div className="flex w-6 h-4">
                            <img
                              src={BSELogo}
                              alt="BSE Logo"
                              height={100}
                              width={100}
                            />
                          </div>
                          <div className="w-[80px] mr-1">{data?.symbol}</div>
                          {data?.name}
                        </div>
                      ))
                    )}
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </nav>
      {selectedFincode && selectedSymbol && (
        <div>
          <HistoricDataNewsPortal
            finCode={selectedFincode}
            symbol={selectedSymbol}
            setisLoggedIn={setisLoggedIn}
            isLoggedIn={isLoggedIn}
          />
        </div>
      )}

      {/* Indices and Sectors data*/}
      {showAdvDeclData && (
        <>
          <p className="text-lg font-bold my-2 font-merriweather ml-4 mr-4">
            Advance / Decline
          </p>
          <div className="container grid grid-cols-1 lg:grid-cols-2 gap-2 mt-2">
            <div className="w-full bg-white shadow rounded px-2 py-2">
              <p className="text-lg font-bold my-2 font-merriweather border-b-2 border-gray-400">
                Indices
              </p>
              <div className="space-y-4 pb-4">
                {indicesData.map((item, idx) => (
                  <div key={idx}>
                    {renderProgressBar(item.ADV, item.DEC, item.INDX_GRP)}
                  </div>
                ))}
              </div>
            </div>
            <div className="w-full bg-white shadow rounded px-2 py-2">
              <p className="text-lg font-bold my-2 font-merriweather border-b-2 border-gray-400">
                Sectors
              </p>
              <div className="space-y-4 pb-4">
                {sectorsData.map((item, idx) => (
                  <div key={idx}>
                    {renderProgressBar(item.ADV, item.DEC, item.INDX_GRP)}
                  </div>
                ))}
              </div>
            </div>
          </div>
        </>
      )}
      <br />
    </>
  );
}

MarketSearchNewsPortal.propTypes = {
  title: PropTypes.string,
};
