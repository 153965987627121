import axios from "axios";
import React, { useEffect, useState } from "react";
import { apiUrl, formatDate } from "../../utils/utils";
import CustomTable from "../../components/CustomTable";
import DownArrow from "../Icons/DownArrow";
import NewsPortalCustomTable from "../NewsPortalCustomTable";

const IPONewsPortalContainer = ({ isLoggedIn }) => {
  const [ipoData, setIpoData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [isTableExpanded, setIsTableExpanded] = useState(false);
  const [tableHeight, setTableHeight] = useState("h-96");
  const [orderingStatus, setOrderingStatus] = useState("");

  const loggedInToken = JSON.parse(localStorage.getItem("WatchlistToken"));
  const tempToken = localStorage.getItem("TempToken");

  const watchlistToken = isLoggedIn ? loggedInToken : tempToken;

  const fetchCurrentPrice = async (finCode) => {
    try {
      const response = await axios.get(
        `https://stock.accordwebservices.com/BSEStock/GetQuoteFinder?fincode=${finCode}&token=${watchlistToken}`
      ); 
      return response?.data?.Table[0]?.CLOSE || "-";
    } catch (err) {
      console.log("Error fetching current price", err);
      return "-";
    }
  };

  const handleIPOData = async (columnName = "-open_date") => {
    try {
      const token = JSON.parse(localStorage.getItem("Token"));

      const config = {
        headers: {
          Authorization: token ? `Bearer ${token}` : "",
        },
      };
      let ordering;
      // Toggle ordering status between ascending and descending

      if (orderingStatus === columnName) {
        ordering = `-${columnName}`;
      } else {
        ordering = columnName;
      }

      if (columnName.includes("%_change")) {
        sortIpoData(ordering);
        return;
      }
      setLoading(true);
      const response = await axios.get(
        apiUrl + `/api/ipo-data?ordering=${ordering}`,
        token ? config : {}
      );
      console.log(response.data);
      const extractedData = await Promise.all(
        response?.data?.map(async (ipo, index) => {
          let currentPrice;
          const { fincode } = ipo;
          const currentDate = new Date();
          currentDate.setDate(currentDate.getDate() - 1);
          const openDate = new Date(formatDate(ipo?.open_date));
          if (currentDate >= openDate) {
            currentPrice = await fetchCurrentPrice(fincode);
          }
          const percentageChange =
            currentPrice == "-" || currentPrice == undefined
              ? "-"
              : ((currentPrice - ipo.issue_price) * 100) / ipo.issue_price;

          return {
            id: ipo?.id,
            open_date: formatDate(ipo?.open_date),
            close_date: formatDate(ipo?.close_date),
            listing_date: formatDate(ipo?.listing_date),
            issuer_company: {
              name: ipo?.issuer_company,
              fincode: ipo?.fincode,
              symbol: ipo?.symbol,
            },
            issue_price: ipo?.issue_price ? ipo?.issue_price : "-",
            current_price: currentPrice === undefined ? "-" : currentPrice,
            issue_size: ipo?.issue_size ? ipo?.issue_size : "-",
            lot_size: ipo?.lot_size ? ipo?.lot_size : "-",
            exchange: ipo?.exchange,
            likes: ipo?.likes,
            dislikes: ipo?.dislikes,
            user_reaction: ipo?.reaction,
            percentageChange:
              percentageChange === "-" || isNaN(percentageChange)
                ? "-"
                : percentageChange.toFixed(2),
          };
        })
      );

      setIpoData(extractedData);
      setLoading(false);
      setOrderingStatus(ordering);
    } catch (err) {
      console.log("Error in IPO Data", err);
      setLoading(false);
    }
  };

  const sortIpoData = (columnName) => {
    // Sort ipoData based on the percentage change column

    const sortedData = [...ipoData]; // Create a copy of ipoData to avoid mutating state directly
    sortedData.sort((a, b) => {
      const percentageA = parseFloat(a.percentageChange);
      const percentageB = parseFloat(b.percentageChange);

      if (isNaN(percentageA) || isNaN(percentageB)) {
        return 0;
      }

      if (columnName === "%_change") {
        return percentageA - percentageB;
      } else if (columnName === "-%_change") {
        return percentageB - percentageA;
      }
    });

    setOrderingStatus(columnName);
    setIpoData(sortedData);
  };

  useEffect(() => {
    handleIPOData();
  }, [watchlistToken]);

  useEffect(() => {
    if (isTableExpanded) {
      setTableHeight("h-auto");
    } else {
      setTableHeight("h-72");
    }
  }, [isTableExpanded]);

  const IpoTableHeader = [
    "Open Date",
    "Close Date",
    "Listing Date",
    "Issuer Company",
    "Issue Price",
    "Current Price",
    "Issue Size",
    "Lot Size",
    "Exchange",
    "% Change",
  ];

  const toggleTableExpansion = () => {
    setIsTableExpanded(!isTableExpanded);
  };

  return (
    <>
      <div className="p-2 mt-2 h-fit w-full rounded-lg overflow-auto bg-white">
        <div className="flex justify-between items-center gap-2 px-4">
          <h1 className="text-primaryBrown font-semibold text-2xl mr-2 md:text-3xl font-merriweather">
            IPO
          </h1>
          <div className="flex items-center justify-between gap-3">
            <div className="flex flex-wrap items-center gap-2">
              <div className="h-1 w-5  bg-yellow-200"></div>
              <p className="text-sm font-merriweather">IPO Open</p>
            </div>
            <div className="flex flex-wrap items-center gap-2">
              <div className="h-1 w-5 bg-green-100"></div>
              <p className="text-sm font-merriweather">Listing Soon</p>
            </div>
            <div className="flex flex-wrap items-center gap-2">
              <div className="h-1 w-5 bg-green-600"></div>
              <p className="text-sm font-merriweather">Listing Today</p>
            </div>
          </div>

          {/* Expand/Collapse Button */}
          {/* <div
            className={`cursor-pointer transform transition-transform duration-300 h-4 ${
              isTableExpanded ? "rotate-180" : "rotate-0"
            }`}
            onClick={toggleTableExpansion}
          >
            <DownArrow />
          </div> */}
        </div>

        {/* Remove the class h-full if need to enable the expand/collapse logic */}
        <div className={`overflow-auto w-full ${tableHeight} h-full`}>
          <NewsPortalCustomTable
            loading={loading}
            headers={IpoTableHeader}
            rowData={ipoData}
            IPO={true}
            useMerriweatherFont={true}
            ipoHeader={true}
            filterClick={handleIPOData}
            orderingStatus={orderingStatus}
            isLoggedIn={isLoggedIn}
          />
        </div>
      </div>
    </>
  );
};

export default IPONewsPortalContainer;
