import React, { useEffect, useRef, useState } from "react";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import CommentIcon from "@mui/icons-material/Comment";
import BookmarksIcon from "@mui/icons-material/Bookmarks";
import NewsAds from "../../components/NewsPortal/NewsAds";
import axios from "axios";
import doubleDownArrow from "../../assets/svg/doubleDownArrow.svg";
import { toast } from "react-toastify";
import parse from "html-react-parser";
import { apiUrl } from "../../utils/utils";
import RelatedNews from "../../components/RelatedNews/RelatedNews";
import CorporateContainer from "../../components/NewsPortal/CorporateContainer";
import StockAlertNewsPortal from "../../components/StockAlertNewsPortal";
import IPONewsPortal from "../IPO/IPONewsPortal";
import MarketSearchNewsPortal from "../../components/MarketSearchNewsPortal";

const NewsContainer = ({ newsTabs, newsData }) => {
  const { newsId } = useParams();
  const location = useLocation();
  const commentsRef = useRef(null);
  const [data, setData] = useState([]);
  const [label, setLabel] = useState("Latest News");
  const [newsItems, setNewsItems] = useState([]);
  const [showCommentInput, setShowCommentInput] = useState(false);
  const [comment, setComment] = useState("");
  const [isLoggedIn, setisLoggedIn] = useState(
    Boolean(localStorage.getItem("Token"))
  );
  const [firstnewsData, setfirstNewsData] = useState([]);
  const [dataFetched, setDataFetched] = useState(false);
  const [expandedNews, setExpandedNews] = useState({});
  const [dynamicNews, setDynamicNews] = useState(null);
  const [viewMoreLabel, setViewMoreLabel] = useState("");
  const navigate = useNavigate();
  const [isViewMoreClicked, setIsViewMoreClicked] = useState(false);

  useEffect(() => {
    if (location.state && location.state.news) {
      setDynamicNews(location.state.news);
    }

    if (location.state && location.state.newLabel) {
      setViewMoreLabel(location.state.newLabel);
    }
  }, [location.state]);

  const sortedNewsData = newsData.sort(
    (a, b) => new Date(b.date) - new Date(a.date)
  );
  const topSevenNews = sortedNewsData.slice(0, 10);
  const primeNews = newsData.filter((news) => news.prime_news);

  const toggleExpandDescription = (index) => {
    setExpandedNews((prevExpandedNews) => ({
      ...prevExpandedNews,
      [index]: true,
    }));
  };

  const handleViewMore = (news, label) => {
    setIsViewMoreClicked(true);
    const newLabel = label.toLowerCase().replace(/ /g, "-");
    navigate(`/news-portal/${newLabel}`, { state: { news, newLabel: label } });
  };

  const getTruncatedText = (text, percentage) => {
    const charLimit = Math.floor((percentage / 100) * text.length);
    return text.slice(0, charLimit);
  };

  useEffect(() => {
    const path = location.pathname;
    const selectedTab = newsTabs.find((tab) => tab.path === path);
    if (selectedTab) {
      setLabel(selectedTab.label);
    } else {
      setLabel("Home");
    }
  }, [location.pathname, newsTabs]);

  useEffect(() => {
    if (newsData && label) {
      filterNews();
    }
  }, [newsData, label]);

  const calculateTimeDifference = (inputTime) => {
    const inputDate = new Date(inputTime);
    const currentDate = new Date();
    const differenceInMilliseconds = currentDate - inputDate;

    const differenceInMinutes = Math.floor(
      differenceInMilliseconds / (1000 * 60)
    );
    const differenceInHours = Math.floor(
      differenceInMilliseconds / (1000 * 60 * 60)
    );
    const differenceInDays = Math.floor(
      differenceInMilliseconds / (1000 * 60 * 60 * 24)
    );

    if (differenceInDays > 0) {
      return `${differenceInDays} day${differenceInDays > 1 ? "s" : ""} ago`;
    } else if (differenceInHours > 0) {
      return `${differenceInHours} hour${differenceInHours > 1 ? "s" : ""} ago`;
    } else {
      return `${differenceInMinutes} minute${
        differenceInMinutes > 1 ? "s" : ""
      } ago`;
    }
  };

  const config = {
    headers: {
      Authorization: `Bearer ${JSON.parse(localStorage.getItem("Token"))}`,
    },
  };

  const filterNews = () => {
    try {
      if (label === "Home") {
        setData(newsData);
      } else {
        const filteredData = newsData.filter((news) => {
          return news.tags.some(
            (tagArray) =>
              Array.isArray(tagArray) &&
              tagArray[0] &&
              typeof tagArray[0] === "string" &&
              tagArray[0].toLowerCase().includes(label.toLowerCase())
          );
        });
        setData(filteredData);
      }
    } catch (error) {
      console.error("Error filtering news:", error);
    }
  };

  useEffect(() => {
    if (data.length > 0) {
      const ids = data.slice(0, 3).map((item) => item.id);
      fetchNewsItems(ids);
      fetchNews();
    } else {
      setNewsItems([]);
    }
  }, [data]);

  const fetchNewsItems = async (ids) => {
    try {
      const responses = await Promise.all(
        ids.map((id) =>
          axios.get(`https://api.dailystockhunt.com/api/portal-news/${id}`)
        )
      );
      setNewsItems(responses.map((res) => res.data));
    } catch (error) {
      console.error("Error fetching news items:", error);
    }
  };

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const options = {
      month: "long",
      day: "numeric",
      hour: "2-digit",
      minute: "2-digit",
    };
    return date.toLocaleDateString("en-IN", options);
  };

  const scrollToComments = () => {
    commentsRef.current.scrollIntoView({ behavior: "smooth", block: "start" });
  };

  const handleAddComment = async () => {
    try {
      await axios.post(
        `${apiUrl}/api/comment/`,
        {
          id: newsId,
          content_type: "portal-news",
          text: comment,
        },
        config
      );
      setShowCommentInput(false);
      setComment("");
    } catch (error) {
      console.error("Error adding comment", error);
    }
  };

  const handelCommentClick = () => {
    if (!isLoggedIn) {
      toast.error("Please login to comment");
    } else {
      setShowCommentInput(!showCommentInput);
      setComment("");
    }
  };

  const handleShareClick = (platform) => {
    const url = window.location.href;
    const title = newsItems[0]?.title;
    const summary = newsItems[0]?.summary;

    let shareUrl = "";

    switch (platform) {
      case "whatsapp":
        shareUrl = `https://api.whatsapp.com/send?text=${title} ${url}`;
        break;
      case "facebook":
        shareUrl = `https://www.facebook.com/sharer/sharer.php?u=${url}`;
        break;
      case "twitter":
        shareUrl = `https://twitter.com/intent/tweet?text=${title}&url=${url}`;
        break;
      case "linkedin":
        shareUrl = `https://www.linkedin.com/sharing/share-offsite/?url=${url}`;
        break;
      default:
        return;
    }

    window.open(shareUrl, "_blank", "noopener,noreferrer");
  };

  const fetchNews = async () => {
    const currentTab = newsTabs.find((tab) => tab.path === location.pathname);
    if (!currentTab) return;

    const tags = currentTab.id;

    try {
      const response = await axios.get(
        `${apiUrl}/api/portal-news?tags=${tags}`
      );
      const filteredNews = response.data.results
        .filter((news) => news.id !== newsId)
        .sort((a, b) => new Date(b.date) - new Date(a.date))
        .slice(0, 3);

      setData(filteredNews);
    } catch (error) {
      console.error("Error fetching related news", error);
    } finally {
      setDataFetched(true);
    }
  };

  const getCategory = (newsItem) => {
    return newsItem.tags.length > 0 ? newsItem.tags[0][0] : "Uncategorized";
  };

  const renderBreadcrumb = () => {
    const paths = label === "Home" ? ["Home", "Latest News"] : ["Home", label];
    return (
      <div className="text-sm font-merriweather">
        {paths.map((path, index) => (
          <span
            key={index}
            className={`inline-block ${
              index === paths.length - 1 ? "text-black" : "text-blue-500"
            }`}
          >
            {index !== 0 && <span className="mx-1"> &gt; </span>}
            {index === paths.length - 1 ? (
              path
            ) : (
              <Link
                to={
                  index === 0
                    ? "/news-portal/home"
                    : `/news-portal/${label.toLowerCase()}`
                }
              >
                {path}
              </Link>
            )}
          </span>
        ))}
      </div>
    );
  };

  if (viewMoreLabel === label && dynamicNews) {
    return (
      <>
        {renderBreadcrumb()}
        <div className="grid grid-cols-1 md:grid-cols-1 lg:grid-cols-1 gap-2 w-6/7 mb-5">
          {dynamicNews.map((news, index) => (
            <div
              key={index}
              onClick={() => navigate(`/news-portal/news/${news.id}`)}
              className="flex items-center bg-white cursor-pointer shadow-md rounded-lg overflow-hidden"
            >
              <div className="w-[180px] h-[120px] flex items-center justify-center bg-gray-200 overflow-hidden">
                {news.image ? (
                  <img
                    src={news.image}
                    alt="news"
                    className="w-full h-full object-cover"
                  />
                ) : (
                  <></>
                )}
              </div>
              <div className="flex flex-col justify-between px-3 py-2 w-full">
                <h3
                  className="text-lg font-semibold text-gray-800 line-clamp-2 font-merriweather"
                  style={{
                    display: "-webkit-box",
                    WebkitLineClamp: 2,
                    WebkitBoxOrient: "vertical",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                  }}
                >
                  {news.title}
                </h3>
                <p
                  className="text-sm text-gray-600 mt-2 line-clamp-3 font-merriweather"
                  style={{
                    display: "-webkit-box",
                    WebkitLineClamp: 3,
                    WebkitBoxOrient: "vertical",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                  }}
                >
                  {parse(news.summary)}
                </p>
              </div>
            </div>
          ))}
        </div>
      </>
    );
  }

  return (
    <div className="mt-3.5">
      {label === "IPO" ? (
        <div className="bg-white shadow rounded">
          <IPONewsPortal setLogin={setisLoggedIn} isLoggedIn={isLoggedIn} />
        </div>
      ) : label === "Markets" ? (
        <div className=" bg-white shadow rounded">
          <MarketSearchNewsPortal login={isLoggedIn} setLogin={setisLoggedIn} />
        </div>
      ) : label === "Home" ? (
        <div className="grid grid-cols-1 lg:grid-cols-2 gap-2">
          <div className="lg:w-[100%] w-full bg-white shadow rounded px-2 py-2">
            <p className="text-lg font-bold my-2 font-merriweather border-b-2 border-gray-400">
              LATEST NEWS
            </p>
            <div className="space-y-2 pb-4 relative">
              <div className="absolute left-1 top-2 bottom-0 w-[2px] bg-blue-500"></div>

              {Array.isArray(topSevenNews) &&
                topSevenNews.map((newsItem, index) => (
                  <div
                    key={index}
                    className="space-y-2 cursor-pointer flex items-start relative pl-6"
                    onClick={() => navigate(`/news-portal/news/${newsItem.id}`)}
                  >
                    <div
                      className={`absolute left-0 top-1 w-3 h-3 rounded-full transition-all duration-300 ${
                        index === 0
                          ? "bg-blue-500"
                          : "border-2 border-blue-500 rounded-full bg-white "
                      }`}
                    ></div>

                    <div>
                      <p className="text-xs text-black">
                        {calculateTimeDifference(newsItem.created_at)} -{" "}
                        {getCategory(newsItem)}
                      </p>
                      <p className="text-sm font-bold hover:underline font-merriweather border-b border-gray-400">
                        {newsItem.title}
                      </p>
                    </div>
                  </div>
                ))}
            </div>
            <div className="flex justify-end">
              <button
                className="text-blue-500 text-sm font-merriweather hover:underline"
                onClick={() => handleViewMore(topSevenNews, "Home")}
              >
                View More
              </button>
            </div>
          </div>
          <div className="w-full bg-white shadow rounded px-2 py-2">
            <p className="text-lg font-bold my-2 font-merriweather border-b-2 border-gray-400">
              PRIME NEWS
            </p>
            {primeNews.length > 0 && (
              <div
                className="mb-3 cursor-pointer"
                onClick={() => navigate(`/news-portal/news/${primeNews[0].id}`)}
              >
                <img
                  src={primeNews[0].image}
                  alt={primeNews[0].title}
                  className="w-full h-48 object-cover rounded"
                />
                <p className="text-xs text-black mt-2">
                  {calculateTimeDifference(primeNews[0].created_at)} -{" "}
                  {primeNews[0].tags[0][0]}
                </p>
                <p className="text-sm font-bold hover:underline font-merriweather mt-1 border-b border-gray-400">
                  {primeNews[0].title}
                </p>
              </div>
            )}

            <div className="space-y-4 pb-4">
              {primeNews.length > 1 ? (
                primeNews.slice(1).map((newsItem, index) => (
                  <div
                    key={index}
                    className="mb-2 cursor-pointer"
                    onClick={() => navigate(`/news-portal/news/${newsItem.id}`)}
                  >
                    <p className="text-xs text-black mt-2">
                      {calculateTimeDifference(newsItem.created_at)} -{" "}
                      {newsItem.tags[0][0]}
                    </p>
                    <p className="text-sm font-bold hover:underline font-merriweather mt-1 border-b border-gray-400">
                      {newsItem.title}
                    </p>
                  </div>
                ))
              ) : (
                <p>No more prime news available</p>
              )}
            </div>
            <div className="flex justify-end">
              <button
                className="text-blue-500 text-sm font-merriweather hover:underline"
                onClick={() => handleViewMore(topSevenNews, "Home")}
              >
                View More
              </button>
            </div>
          </div>
        </div>
      ) : label === "Corporate News" ? (
        <div className="">
          <CorporateContainer />
        </div>
      ) : label === "Alerts" ? (
        <div className="bg-white shadow rounded w-full font-merriweather">
          <StockAlertNewsPortal
            setLogin={setisLoggedIn}
            isLoggedIn={isLoggedIn}
          />
        </div>
      ) : (
        <div className="bg-white shadow rounded min-h-[800px]">
          {newsItems.length > 0 ? (
            newsItems.map((newsItem, index) => (
              <div key={index} className="py-4 px-6 w-full">
                <h2 className="text-4xl font-semibold mb-2 text-black font-merriweather">
                  {newsItem.title}
                </h2>
                <div>
                  <p
                    className="text-md my-8 bg-yellow-100 px-4 py-3 italic border-l-4 border-black font-merriweather"
                    dangerouslySetInnerHTML={{ __html: newsItem.summary }}
                  />
                  <p className="text-xs text-gray-500 mb-1 font-semibold font-merriweather">
                    {newsItem.author}
                  </p>
                  <p className="text-xs text-gray-500 mb-4 font-merriweather">
                    {formatDate(newsItem.updated_at)}
                  </p>
                  <div className="flex justify-between items-center mt-12 mb-2">
                    <div className="flex space-x-3">
                      <button
                        className="flex items-center text-blue-900 text-sm"
                        onClick={scrollToComments}
                      >
                        <CommentIcon /> COMMENTS
                      </button>
                      <div className="flex space-x-3">
                        <button
                          className="flex items-center text-blue-900 text-sm"
                          onClick={() => handleShareClick("whatsapp")}
                        >
                          <img
                            src="/whatsapp.png"
                            alt="Share on WhatsApp"
                            className="w-7 h-7"
                          />
                        </button>
                        <button
                          className="flex items-center text-blue-900 text-sm"
                          onClick={() => handleShareClick("facebook")}
                        >
                          <img
                            src="/facebook.png"
                            alt="Share on Facebook"
                            className="w-7 h-7"
                          />
                        </button>
                        <button
                          className="flex items-center text-blue-900 text-sm"
                          onClick={() => handleShareClick("twitter")}
                        >
                          <img
                            src="/twitter.png"
                            alt="Share on Twitter"
                            className="w-7 h-7"
                          />
                        </button>
                        <button
                          className="flex items-center text-blue-900 text-sm"
                          onClick={() => handleShareClick("linkedin")}
                        >
                          <img
                            src="/linkedin.png"
                            alt="Share on LinkedIn"
                            className="w-7 h-7"
                          />
                        </button>
                      </div>
                    </div>
                    <div>
                      <button className="flex items-center text-blue-900 text-sm">
                        <BookmarksIcon />
                        READ LATER
                      </button>
                    </div>
                  </div>
                </div>
                <div className="w-full h-[400px] mb-2">
                  {newsItem.image ? (
                    <img
                      src={newsItem.image}
                      alt="news"
                      className="w-full h-full object-cover rounded-lg"
                    />
                  ) : (
                    <div className="w-full h-full bg-gray-300 rounded-lg"></div>
                  )}
                </div>
                {newsItem.image_description && (
                  <p className="text-xs text-gray-500 py-2 font-merriweather">
                    {newsItem.image_description}
                  </p>
                )}

                <div className="prose max-w-full py-2">
                  <p className="font-merriweather">
                    {index === 0
                      ? parse(newsItem.description)
                      : expandedNews[index]
                      ? parse(newsItem.description)
                      : parse(getTruncatedText(newsItem.description, 5))}
                  </p>

                  {!expandedNews[index] && index !== 0 && (
                    <div className="flex justify-center">
                      <button
                        onClick={() => toggleExpandDescription(index)}
                        className="text-white bg-orange-500 px-3 py-1 text-lg rounded font-merriweather"
                      >
                        Read more
                      </button>
                    </div>
                  )}
                </div>
                <div className="flex justify-between items-center mt-12 mb-2">
                  <div className="flex space-x-3">
                    <button
                      className="flex items-center text-gray-500 text-sm"
                      onClick={scrollToComments}
                    >
                      <CommentIcon /> COMMENTS
                    </button>
                    <div className="flex space-x-3">
                      <button
                        className="flex items-center text-blue-900 text-sm"
                        onClick={() => handleShareClick("whatsapp")}
                      >
                        <img
                          src="/whatsapp.png"
                          alt="Share on WhatsApp"
                          className="w-7 h-7"
                        />
                      </button>
                      <button
                        className="flex items-center text-blue-900 text-sm"
                        onClick={() => handleShareClick("facebook")}
                      >
                        <img
                          src="/facebook.png"
                          alt="Share on Facebook"
                          className="w-7 h-7"
                        />
                      </button>
                      <button
                        className="flex items-center text-blue-900 text-sm"
                        onClick={() => handleShareClick("twitter")}
                      >
                        <img
                          src="/twitter.png"
                          alt="Share on Twitter"
                          className="w-7 h-7"
                        />
                      </button>
                      <button
                        className="flex items-center text-blue-900 text-sm"
                        onClick={() => handleShareClick("linkedin")}
                      >
                        <img
                          src="/linkedin.png"
                          alt="Share on LinkedIn"
                          className="w-7 h-7"
                        />
                      </button>
                    </div>
                  </div>
                </div>
                <div ref={commentsRef} className="border-y py-4">
                  <div className="mb-2 pb-2 border-b flex justify-between">
                    <p className="text-xl font-bold ">Comments</p>
                    <button
                      className="bg-black text-white font-bold py-2 px-4 rounded-lg font-merriweather"
                      onClick={handelCommentClick}
                    >
                      {showCommentInput ? "Close Comment" : "Add Comment"}
                    </button>
                  </div>
                  {showCommentInput && (
                    <div className="flex flex-col space-y-2 mb-4">
                      <textarea
                        value={comment}
                        onChange={(e) => setComment(e.target.value)}
                        className="border border-gray-300 rounded p-2 w-full"
                        placeholder="Add your comment here..."
                      />
                      <button
                        onClick={handleAddComment}
                        className="bg-blue-500 text-white py-2 px-4 rounded"
                      >
                        Submit Comment
                      </button>
                    </div>
                  )}
                  {index !== newsItems.length - 1 && (
                    <div className="mt-5">
                      <div className="flex items-center">
                        <div className="flex-grow border-t border-black border-2"></div>
                        <h2 className="mx-4 text-center text-gray-700 font-medium text-sm">
                          READ NEXT STORY
                        </h2>
                        <div className="flex-grow border-t border-black border-2"></div>
                      </div>
                      <div className="flex justify-center mt-2 font-bold">
                        <img
                          src={doubleDownArrow}
                          alt="Double Down Arrow"
                          className="h-6 w-6"
                        />
                      </div>
                    </div>
                  )}
                </div>
              </div>
            ))
          ) : (
            <div className="flex flex-col items-center bg-white z-10 p-10">
              <h2 className="text-2xl font-semibold mb-2 text-black font-merriweather">
                No News Available
              </h2>
              <p className="text-md text-gray-500">
                Please check back later for the latest news.
              </p>
            </div>
          )}
          {newsItems.length > 0 && (
            <>
              <RelatedNews newsData={firstnewsData} newsId={newsId} />
              <div className="flex flex-col md:flex-row w-full gap-4 min-h-[80px]">
                <div className="w-full md:w-[30%]">
                  <NewsAds />
                </div>
              </div>
            </>
          )}
        </div>
      )}
    </div>
  );
};

export default NewsContainer;
