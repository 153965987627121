import React from "react";
import Switch from "@mui/material/Switch";
import "../CustomToggle/customToggle.css";

const CustomToggle = ({ value, onToggle, useMerriweatherFont }) => {
  const handleChange = () => {
    onToggle(value === "gainers" ? "losers" : "gainers");
  };

  return (
    <>
      <div className="toggle-container flex items-center gap-2">
        <p
          onClick={handleChange}
          className={`toggle-label font-semibold text-success cursor-pointer ${useMerriweatherFont ? "font-merriweather" : ""}`}
        >
          Gainers
        </p>
        <label className="switch">
          <input
            type="checkbox"
            checked={value === "losers"}
            onChange={handleChange}
          />
          <span className="slider bg-primaryHovered round"></span>
        </label>
        <p
          onClick={handleChange}
          className={`toggle-label font-semibold text-error cursor-pointer ${useMerriweatherFont ? "font-merriweather" : ""}`}
        >
          Losers
        </p>
      </div>
    </>
  );
};

export default CustomToggle;
