import React, { useEffect, useRef, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import { apiUrl } from "../../utils/utils";
import ResultsCompaniesIPOSkeleton from "../Skeleton/ResultsCompaniesIPOSkeleton";

const ResultsCompaniesIPOSection = ({ newsTabs, newsData }) => {
  const { newsId } = useParams();
  const location = useLocation();
  const commentsRef = useRef(null);
  const [data, setData] = useState([]);
  const [label, setLabel] = useState("Latest News");
  const [newsItems, setNewsItems] = useState([]);
  const [dataFetched, setDataFetched] = useState(false);
  const [dynamicNews, setDynamicNews] = useState(null);
  const [viewMoreLabel, setViewMoreLabel] = useState("");
  const navigate = useNavigate();
  const [isViewMoreClicked, setIsViewMoreClicked] = useState(false);
  const [isImageLoaded, setIsImageLoaded] = useState(false);

  const fetchNews = async () => {
    const currentTab = newsTabs.find((tab) => tab.path === location.pathname);
    if (!currentTab) return;

    const tags = currentTab.id;

    try {
      const response = await axios.get(
        `${apiUrl}/api/portal-news?tags=${tags}`
      );
      const filteredNews = response.data.results
        .filter((news) => news.id !== newsId)
        .sort((a, b) => new Date(b.date) - new Date(a.date))
        .slice(0, 3);

      setData(filteredNews);
    } catch (error) {
      console.error("Error fetching related news", error);
    } finally {
      setDataFetched(true);
    }
  };

  const fetchNewsItems = async (ids) => {
    try {
      const responses = await Promise.all(
        ids.map((id) =>
          axios.get(`https://api.dailystockhunt.com/api/portal-news/${id}`)
        )
      );
      setNewsItems(responses.map((res) => res.data));
    } catch (error) {
      console.error("Error fetching news items:", error);
    }
  };

  useEffect(() => {
    fetchNews().then(() => {
      setDataFetched(true); // Set to true once data is fetched
    });
  }, [newsTabs, location.pathname]);

  useEffect(() => {
    if (location.state && location.state.news) {
      setDynamicNews(location.state.news);
    }

    if (location.state && location.state.newLabel) {
      setViewMoreLabel(location.state.newLabel);
    }
  }, [location.state]);

  const sortedNewsData = newsData.sort(
    (a, b) => new Date(b.date) - new Date(a.date)
  );
  const topSevenNews = sortedNewsData.slice(0, 10);

  const resultsNews = newsData
    .filter((news) =>
      news.tags.some((tagArray) => tagArray.includes("Results"))
    )
    .slice(0, 7);
  const companiesNews = newsData
    .filter((news) =>
      news.tags.some((tagArray) => tagArray.includes("companies"))
    )
    .slice(0, 7);
  const ipoNews = newsData
    .filter((news) => news.tags.some((tagArray) => tagArray.includes("IPO")))
    .slice(0, 7);

  const handleViewMore = (news, label) => {
    setIsViewMoreClicked(true);
    const newLabel = label.toLowerCase().replace(/ /g, "-");
    navigate(`/news-portal/${newLabel}`, { state: { news, newLabel: label } });
  };

  useEffect(() => {
    const path = location.pathname;
    const selectedTab = newsTabs.find((tab) => tab.path === path);
    if (selectedTab) {
      setLabel(selectedTab.label);
    } else {
      setLabel("Home");
    }
  }, [location.pathname, newsTabs]);

  useEffect(() => {
    if (newsData && label) {
      filterNews();
    }
  }, [newsData, label]);

  const calculateTimeDifference = (inputTime) => {
    const inputDate = new Date(inputTime);
    const currentDate = new Date();
    const differenceInMilliseconds = currentDate - inputDate;

    const differenceInMinutes = Math.floor(
      differenceInMilliseconds / (1000 * 60)
    );
    const differenceInHours = Math.floor(
      differenceInMilliseconds / (1000 * 60 * 60)
    );
    const differenceInDays = Math.floor(
      differenceInMilliseconds / (1000 * 60 * 60 * 24)
    );

    if (differenceInDays > 0) {
      return `${differenceInDays} day${differenceInDays > 1 ? "s" : ""} ago`;
    } else if (differenceInHours > 0) {
      return `${differenceInHours} hour${differenceInHours > 1 ? "s" : ""} ago`;
    } else {
      return `${differenceInMinutes} minute${
        differenceInMinutes > 1 ? "s" : ""
      } ago`;
    }
  };

  const filterNews = () => {
    try {
      if (label === "Home") {
        setData(newsData);
      } else {
        const filteredData = newsData.filter((news) => {
          return news.tags.some(
            (tagArray) =>
              Array.isArray(tagArray) &&
              tagArray[0] &&
              typeof tagArray[0] === "string" &&
              tagArray[0].toLowerCase().includes(label.toLowerCase())
          );
        });
        setData(filteredData);
      }
    } catch (error) {
      console.error("Error filtering news:", error);
    }
  };

  useEffect(() => {
    if (data.length > 0) {
      const ids = data.slice(0, 3).map((item) => item.id);
      fetchNewsItems(ids);
      fetchNews();
    } else {
      setNewsItems([]);
    }
  }, [data]);

  if (!dataFetched) {
    return <ResultsCompaniesIPOSkeleton />; // Render the skeleton when data is not yet fetched
  }

  return (
    <div className="container-width grid grid-cols-1 lg:grid-cols-3 gap-2">
      <div className="w-full bg-white shadow rounded px-2 py-2">
        <p className="text-lg font-bold my-2 font-merriweather border-b-2 border-gray-400">
          RESULTS
        </p>
        <div className="space-y-4 pb-4">
          {resultsNews.length > 0 ? (
            resultsNews.map((newsItem, index) => (
              <div
                key={index}
                className="mb-2 cursor-pointer"
                onClick={() => navigate(`/news-portal/news/${newsItem.id}`)}
              >
                {index === 0 && (
                  <>
                    {!isImageLoaded && (
                      <div className="w-full h-48 bg-gray-200 rounded animate-pulse"></div>
                    )}
                    <img
                      src={newsItem.image}
                      alt={newsItem.title}
                      className={`w-full h-48 object-cover rounded ${
                        isImageLoaded ? "opacity-100" : "opacity-0"
                      }`}
                      style={{ transition: "opacity 0.5s ease-in-out" }}
                      loading="lazy"
                      onLoad={() => setIsImageLoaded(true)}
                    />
                  </>
                )}
                <p className="text-xs text-black mt-2">
                  {calculateTimeDifference(newsItem.created_at)} -{" "}
                  {newsItem.tags[0][0]}
                </p>
                <p className="text-sm font-bold hover:underline font-merriweather mt-1 border-b border-gray-400">
                  {newsItem.title}
                </p>
              </div>
            ))
          ) : (
            <p>No more Results news available</p>
          )}
        </div>
        <div className="flex justify-end">
          <button
            className="text-blue-500 text-sm font-merriweather hover:underline"
            onClick={() => handleViewMore(topSevenNews, "Results")}
          >
            View More
          </button>
        </div>
      </div>
      <div className="w-full bg-white shadow rounded px-2 py-2">
        <p className="text-lg font-bold my-2 font-merriweather border-b-2 border-gray-400">
          COMPANIES
        </p>
        <div className="space-y-4 pb-4">
          {companiesNews.length > 0 ? (
            companiesNews.map((newsItem, index) => (
              <div
                key={index}
                className="mb-2 cursor-pointer"
                onClick={() => navigate(`/news-portal/news/${newsItem.id}`)}
              >
                {index === 0 && (
                  <>
                    {!isImageLoaded && (
                      <div className="w-full h-48 bg-gray-200 rounded animate-pulse"></div>
                    )}
                    <img
                      src={newsItem.image}
                      alt={newsItem.title}
                      className={`w-full h-48 object-cover rounded ${
                        isImageLoaded ? "opacity-100" : "opacity-0"
                      }`}
                      style={{ transition: "opacity 0.5s ease-in-out" }}
                      loading="lazy"
                      onLoad={() => setIsImageLoaded(true)}
                    />
                  </>
                )}
                <p className="text-xs text-black mt-2">
                  {calculateTimeDifference(newsItem.created_at)} -{" "}
                  {newsItem.tags[0][0]}
                </p>
                <p className="text-sm font-bold hover:underline font-merriweather mt-1 border-b border-gray-400">
                  {newsItem.title}
                </p>
              </div>
            ))
          ) : (
            <p>No more Companies news available</p>
          )}
        </div>
        <div className="flex justify-end">
          <button
            className="text-blue-500 text-sm font-merriweather hover:underline"
            onClick={() => handleViewMore(topSevenNews, "Companies")}
          >
            View More
          </button>
        </div>
      </div>
      <div className="w-full bg-white shadow rounded px-2 py-2">
        <p className="text-lg font-bold my-2 font-merriweather border-b-2 border-gray-400">
          IPO
        </p>
        <div className="space-y-4 pb-4">
          {ipoNews.length > 0 ? (
            ipoNews.map((newsItem, index) => (
              <div
                key={index}
                className="mb-2 cursor-pointer"
                onClick={() => navigate(`/news-portal/news/${newsItem.id}`)}
              >
                {index === 0 && (
                  <>
                    {!isImageLoaded && (
                      <div className="w-full h-48 bg-gray-200 rounded animate-pulse"></div>
                    )}
                    <img
                      src={newsItem.image}
                      alt={newsItem.title}
                      className={`w-full h-48 object-cover rounded ${
                        isImageLoaded ? "opacity-100" : "opacity-0"
                      }`}
                      style={{ transition: "opacity 0.5s ease-in-out" }}
                      loading="lazy"
                      onLoad={() => setIsImageLoaded(true)}
                    />
                  </>
                )}
                <p className="text-xs text-black mt-2">
                  {calculateTimeDifference(newsItem.created_at)} -{" "}
                  {newsItem.tags[0][0]}
                </p>
                <p className="text-sm font-bold hover:underline font-merriweather mt-1 border-b border-gray-400">
                  {newsItem.title}
                </p>
              </div>
            ))
          ) : (
            <p>No more IPO news available</p>
          )}
        </div>
        <div className="flex justify-end">
          <button
            className="text-blue-500 text-sm font-merriweather hover:underline"
            onClick={() => handleViewMore(topSevenNews, "IPO")}
          >
            View More
          </button>
        </div>
      </div>
    </div>
  );
};

export default ResultsCompaniesIPOSection;
