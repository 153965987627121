import axios from "axios";
import { toast } from "react-toastify";

const apiHost = process.env.REACT_APP_BACKEND_URL;

// Define or import createSlug
const createSlug = (text) => {
  return text
    .toLowerCase()
    .replace(/[^a-z0-9]+/g, "-") // Replace non-alphanumeric characters with hyphens
    .replace(/^-+|-+$/g, ""); // Remove leading or trailing hyphens
};

export const fetchNewsTabs = async () => {
  try {
    const response = await axios.get(`${apiHost}/api/portal-news-tags`);
    const sortedTabs = response.data.sort((a, b) => a.order - b.order);
    const tabs = sortedTabs.map((tab) => ({
      label: tab.tag,
      path: `/news-portal/${createSlug(tab.tag)}`, // Use the createSlug function here
      id: tab.id,
    }));
    tabs.unshift({ label: "Home", path: "/news-portal/home" });
    return tabs;
  } catch (error) {
    console.error("Error fetching news tabs", error);
    throw error;
  }
};

export const searchNews = async (query) => {
  try {
    const response = await axios.get(`${apiHost}/api/portal-news?search=${query}`);
    return response.data.results;
  } catch (error) {
    console.error("Error fetching search results", error);
    toast.error("Error fetching search results");
    throw error; 
  }
};

export const googleLoginAPI = async (access_token) => {
  try {
    const response = await axios.post(`${apiHost}/auth/google/`, {
      access_token,
    });
    return response.data;
  } catch (error) {
    toast.error("Google login failed");
    throw error;
  }
};

export const fetchRelatedNews = async (tabId, setNews) => {
  try {
    const response = await axios.get(
      `${apiHost}/api/portal-news?tags=${tabId}`
    );
    setNews(response.data.results);
  } catch (error) {
    console.error("Error fetching related news", error);
  }
};

export const parseJwt = (token) => {
  try {
    return JSON.parse(atob(token.split(".")[1]));
  } catch (e) {
    return null;
  }
};

export const getCommonNews = async (tag, currentPage = 1, pageSize = 8) => {
  try {
    const url =
      tag?.length > 0
        ? `/api/news-feed?tag=${tag}&page_size=8&page=${currentPage}`
        : `/api/news-feed?page=${currentPage}&page_size=${pageSize}`;

    const response = await axios.get(process.env.REACT_APP_BACKEND_URL + url);

    if (response.data) {
      const responseData = {
        news: [...response.data.results.news],
        tags: [...response.data.results.new_news_tags],
        next: response.data.next,
        previous: response.data.previous,
      };

      return responseData;
    } else {
      console.error("Unexpected response:", response.data);
      return null;
    }
  } catch (error) {
    console.error("Error fetching news:", error);
    throw error;
  }
};

export const getNextData = async (currentPage, pageSize = 8, news = []) => {
  let nextPage = currentPage + 1;
  try {
    const response = await axios.get(
      process.env.REACT_APP_BACKEND_URL +
        `/api/news-feed?page=${nextPage}&page_size=${pageSize}`
    );

    if (response.data) {
      const newData = response.data.results.news;

      const filteredData = newData.filter(
        (item) =>
          !news.some((existingItem) => existingItem.title === item.title)
      );

      return {
        news: filteredData,
        next: response.data.next,
        previous: response.data.previous,
        nextPage,
      };
    } else {
      console.error("Unexpected response:", response.data);
      return null;
    }
  } catch (error) {
    console.error("Error fetching next news:", error);
    throw error;
  }
};

export const removeQueryParams = (navigate, getCommonNews) => {
  const queryParams = new URLSearchParams();
  queryParams.delete("tag");

  navigate(`/news?${queryParams.toString()}`);

  // Fetch default news feed
  getCommonNews();
};
