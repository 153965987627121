// Sidebar.js
import React, { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import RightArrowBold from "../../assets/svg/rightArrowBold.svg";
import YoutubeEmbed from "./YoutubeEmbed";
import { useNewsTabs } from "./hooks";
import { fetchRelatedNews } from "./api";
import IndustrySection from "./IndustrySection"; // Import the new component

const Sidebar = ({ newsData }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const { newsTabs, loading } = useNewsTabs();
  const [resultsNews, setResultsNews] = useState([]);

  const sortedNewsData = newsData.sort(
    (a, b) => new Date(b.date) - new Date(a.date)
  );
  const topThreeNews = sortedNewsData.slice(0, 3);
  // console.log("sidebar---", newsData);

  useEffect(() => {
    const resultsTab = newsTabs.find((tab) => tab.label === "Results");
    if (resultsTab) {
      fetchRelatedNews(resultsTab.id, setResultsNews);
    }
  }, [newsTabs]);

  const handleViewMore = (news, label) => {
    const newLabel = label.toLowerCase().replace(/ /g, "-");
    navigate(`/news-portal/${newLabel}`, { state: { news, newLabel: label } });
  };

  const isHomePage = location.pathname === "/news-portal/home";

  return (
    <div className="lg:w-1/4 w-full rounded-lg lg:mt-3.5 mt-6">
      {!isHomePage && (
        <div className="px-2 py-2 bg-[#f5f5dc] rounded">
          <p className="text-lg font-bold my-2 font-merriweather border-b-2 border-gray-400">
            LATEST NEWS
          </p>
          <div className="space-y-2 pb-4 ">
            {Array.isArray(topThreeNews) &&
              topThreeNews.map((newsItem, index) => (
                <div
                  key={index}
                  className="space-y-2 pb-2 cursor-pointer flex items-start border-b border-gray-400"
                  onClick={() => navigate(`/news-portal/news/${newsItem.id}`)}
                >
                  <div className="mr-2 flex-shrink-0 mt-2.5">
                    <img
                      src={RightArrowBold}
                      alt="Right Arrow"
                      className="w-4 h-4"
                    />
                  </div>
                  <div className="flex-grow">
                    <p className="text-sm text-gray-500 font-merriweather">
                      {newsItem.category}
                    </p>
                    <p
                      className="text-sm font-bold hover:underline font-merriweather pb-1 line-clamp-3"
                      style={{
                        display: "-webkit-box",
                        WebkitLineClamp: 3,
                        WebkitBoxOrient: "vertical",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                      }}
                    >
                      {newsItem.title}
                    </p>
                  </div>
                  <div className="ml-2 w-16 h-16 aspect-square">
                    <img
                      src={newsItem.image}
                      alt={newsItem.title}
                      className="w-full h-full object-cover"
                    />
                  </div>
                </div>
              ))}
          </div>
          <div className="flex justify-end">
            <button
              className="text-blue-500 text-sm font-merriweather hover:underline"
              onClick={() => handleViewMore(topThreeNews, "Home")}
            >
              View More
            </button>
          </div>
        </div>
      )}

      {/* Results Section */}
      <div className="px-2 py-2 bg-[#f2e8e0] rounded">
        <p className="text-lg font-bold my-2 font-merriweather border-b-2 border-gray-400">
          RESULTS
        </p>
        <div className="space-y-2 pb-4">
          {Array.isArray(resultsNews) &&
            resultsNews.map((newsItem, index) => (
              <div
                key={index}
                className="flex items-start cursor-pointer space-x-2 pb-2 border-b border-gray-400"
                onClick={() => navigate(`/news-portal/news/${newsItem.id}`)}
              >
                <div className="flex-shrink-0">
                  <img
                    src={RightArrowBold}
                    alt="Right Arrow"
                    className="w-4 h-4 mt-1"
                  />
                </div>
                <div className="flex-grow">
                  <p className="text-sm text-gray-500 font-merriweather">
                    {newsItem.category}
                  </p>
                  <p
                    className="text-sm hover:underline font-merriweather pb-1 line-clamp-3 font-bold"
                    style={{
                      display: "-webkit-box",
                      WebkitLineClamp: 3,
                      WebkitBoxOrient: "vertical",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                    }}
                  >
                    {newsItem.title}
                  </p>
                </div>
                <div className="w-16 h-16 aspect-square">
                  <img
                    src={newsItem.image}
                    alt={newsItem.title}
                    className="w-full h-full object-cover"
                  />
                </div>
              </div>
            ))}
        </div>
        <div className="flex justify-end">
          <button
            className="text-blue-500 text-sm font-merriweather hover:underline"
            onClick={() => handleViewMore(resultsNews, "Results")}
          >
            View More
          </button>
        </div>
      </div>

      {/* Youtube Videos Section */}
      <div className="p-4 bg-white rounded">
        <p className="text-xl font-bold my-4 font-merriweather">
          Youtube Videos
        </p>
        <YoutubeEmbed embedId="tC_qwBJ1kos" />
        <YoutubeEmbed embedId="tC_qwBJ1kos" />
      </div>
    </div>
  );
};

export default Sidebar;
