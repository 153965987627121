import React from "react";
import IPONewsPortalContainer from "../../components/IPO/IPONewsPortalContainer";

const IPONewsPortal = ({ isLoggedIn }) => {
  return (
    <div className="md:flex mt-16 md:mt-2 md:pl-0 overflow-auto">
      <IPONewsPortalContainer isLoggedIn={isLoggedIn} />
    </div>
  );
};

export default IPONewsPortal;
