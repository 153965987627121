import React, { useEffect, useRef, useState } from "react";
import CustomToggle from "./CustomToggle/CustomToggle";
import { Tabs, Tab, Pagination, Button } from "@mui/material";
import CustomChip from "./CustomChip";
import CustomTable from "./CustomTable";
import CustomSlider from "./CustomSlider";
import { generateToken } from "../utils/generateWatchlistToken";
import Loader from "./Loader/Loader";
import { accordBaseURl } from "../utils/utils";

const PAGE_SIZE = 6;

const GainersAndLosersNewsPortal = () => {
  const [selectedToggle, setSelectedToggle] = useState("gainers");
  const [selectedFilter, setSelectedFilter] = useState("Daily");
  const [selectedTab, setSelectedTab] = useState("Group A");
  const [stockData, setStockData] = useState([]);
  const [rowData, setRowData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [isLoading, setISLoading] = useState(false);
  const [minMarketCap, setMinMarketCap] = useState(0);
  const [filteredStockData, setFilteredStockData] = useState([]);

  const tempToken = localStorage.getItem("TempToken");

  const handleToggle = (newValue) => {
    setSelectedToggle(newValue);
  };

  const handleFilterClick = (title) => {
    setSelectedFilter(title);
  };

  const handleTabChange = (event, newValue) => {
    setSelectedTab(newValue);
  };

  useEffect(() => {
    fetchData();
  }, [selectedFilter, selectedToggle, selectedTab]);

  const fetchData = async () => {
    setISLoading(true);
    setCurrentPage(1);
    const watchlistToken = tempToken
      ? tempToken
      : JSON.parse(localStorage.getItem("WatchlistToken"));
    const type = selectedToggle === "gainers" ? "GAIN" : "LOSS";
    const groupValue = getGroupValue(selectedTab);

    const apiUrl = `https://stock.accordwebservices.com/BSEStock/GetGainersnLosers?ExCode=BE49H6S&Group=${groupValue}&Indices=&Type=${type}&Option=&Period=${selectedFilter.toUpperCase()}&PageNo=${1}&Pagesize=${PAGE_SIZE}&SortExpression=&SortDirect=&token=${watchlistToken}`;

    try {
      const response = await fetch(apiUrl);

      if (response.ok) {
        const data = await response.json();

        setTotalPages(data.Table1[0].TotalRows);
        setStockData(data.Table);

        const extractedData = data.Table.map((stock) => ({
          company: stock.S_NAME,
          currentPrice: stock.CLOSE_PRICE,
          percentageChange: stock.PERCHG,
        }));
        setRowData(extractedData);
        setISLoading(false);
        setCurrentPage((prevPage) => prevPage + 1);
      } else {
        throw new Error("Failed to fetch data");
      }
    } catch (error) {
      setISLoading(false);
      if (error && localStorage.getItem("Token")) {
        console.log("Came here::", error);
        generateToken(fetchData);
      }
      console.error("Error fetching data:", error);
    }
  };

  const handleLoadMore = async () => {
    // setISLoading(true);
    const watchlistToken = tempToken
      ? tempToken
      : JSON.parse(localStorage.getItem("WatchlistToken"));
    const type = selectedToggle === "gainers" ? "Gain" : "Lose";
    const groupValue = getGroupValue(selectedTab);
    const page_size = (totalPages - PAGE_SIZE - 1).toString();

    const apiUrl = `${accordBaseURl}/BSEStock/GetGainersnLosers?ExCode=BE49H6S&Group=${groupValue}&Indices=&Type=${type}&Option=&Period=${selectedFilter.toUpperCase()}&PageNo=${currentPage}&Pagesize=${PAGE_SIZE}&SortExpression=&SortDirect=&token=${watchlistToken}`;

    try {
      const response = await fetch(apiUrl);

      if (response.ok) {
        const data = await response.json();
        const newExtractedData = data.Table.map((stock) => ({
          company: stock.S_NAME,
          prevClose: stock.PREVCLOSE,
          currentPrice: stock.CLOSE_PRICE,
          percentageChange: stock.PERCHG,
        }));

        setRowData((prevData) => [...prevData, ...newExtractedData]);
        setStockData((prevData) => [...prevData, ...data.Table]);
        setCurrentPage((prevPage) => prevPage + 1);
        setTotalPages(data.Table1[0].TotalRows);
        // setISLoading(false);
      } else {
        throw new Error("Failed to fetch data");
      }
    } catch (error) {
      if (error && localStorage.getItem("Token")) {
        console.log("Came here::", error);
        generateToken(fetchData);
      }
      console.error("Error fetching data:", error);
    } finally {
      // setISLoading(false);
    }
  };

  const handlePageChange = (event, newPage) => {
    handleLoadMore();
  };

  const getGroupValue = (selectedTab) => {
    // Logic to map the selected tab to the corresponding group value
    switch (selectedTab) {
      case "Group A":
        return "A";
      case "Group B":
        return "B";
      case "Group M":
        return "M";
      case "Group T":
        return "T";
      case "Group MT":
        return "MT";
      case "Group Z":
        return "Z";
      default:
        return "A"; // Default to 'Group A' if not matched
    }
  };

  const tableHeader = ["Company Name", "Current Price(₹)", "% Change"];

  const filterData = (minCap) => {
    const filteredData = stockData.filter((stock) => {
      const marketCapValue = parseFloat(stock.MCAP);
      return marketCapValue >= minCap;
    });
    setFilteredStockData(filteredData);
    const newExtractedData = filteredData.map((stock) => ({
      company: stock.S_NAME,
      currentPrice: stock.CLOSE_PRICE,
      percentageChange: stock.PERCHG,
    }));
    setRowData(newExtractedData);
  };

  useEffect(() => {
    if (selectedFilter === "Daily") setTimeout(filterData(minMarketCap), 500);
  }, [minMarketCap, stockData]);

  return (
    <div
      className="bg-white shadow rounded mt-2 px-2 py-2 lg:ml-2 lg:w-[97%]"
      style={{ fontFamily: "Merriweather" }}
    >
      <p className="text-lg font-bold my-2 font-merriweather border-b-2 border-gray-400">
        GAINERS & LOSERS
      </p>
      <div className="flex gap-10 items-center justify-center flex-wrap">
        <div>
          <div className="text-center flex justify-center items-center h-[100%]">
            <CustomToggle
              value={selectedToggle}
              onToggle={handleToggle}
              useMerriweatherFont={true}
            />
          </div>
          <div className="flex gap-2 mt-4 flex-wrap">
            <CustomChip
              title={"Daily"}
              selected={selectedFilter === "Daily"}
              onClick={() => handleFilterClick("Daily")}
              useMerriweatherFont={true}
            />
            <CustomChip
              title={"Monthly"}
              selected={selectedFilter === "Monthly"}
              onClick={() => handleFilterClick("Monthly")}
              useMerriweatherFont={true}
            />
            <CustomChip
              title={"Yearly"}
              selected={selectedFilter === "Yearly"}
              onClick={() => handleFilterClick("Yearly")}
              useMerriweatherFont={true}
            />
          </div>
        </div>
      </div>

      <div className="mt-2">
        <Tabs
          value={selectedTab}
          onChange={handleTabChange}
          sx={{
            "& .MuiTabs-indicator": {
              height: "4px",
              borderRadius: "15px 15px 0px 0px",
            },
          }}
          variant="scrollable"
          scrollButtons
          allowScrollButtonsMobile
        >
          {["Group A", "Group B", "Group T", "Group Z"].map((tabLabel) => (
            <Tab
              key={tabLabel}
              label={tabLabel}
              value={tabLabel}
              sx={{
                fontWeight: selectedTab === tabLabel ? "bold" : "normal",
                padding: "1px",
                borderBottomLeftRadius: "10px",
                borderBottomRightRadius: "10px",
                fontFamily: "Merriweather",
              }}
            />
          ))}
        </Tabs>
        <CustomTable
          headers={tableHeader}
          rowData={rowData}
          type={selectedToggle === "gainers" ? "increase" : "decrease"}
          loading={isLoading}
          useMerriweatherFont={true}
        />

        <div className="flex justify-center mt-4">
          {currentPage < Math.ceil(totalPages / PAGE_SIZE) && !isLoading && (
            <Button
              variant="contained"
              className="rounded-full"
              onClick={handlePageChange}
              style={{ fontFamily: "Merriweather" }}
            >
              Load More
            </Button>
          )}
        </div>
      </div>
    </div>
  );
};

export default GainersAndLosersNewsPortal;
