import React, { useState, useEffect } from "react";
import { NavLink, useLocation } from "react-router-dom";
import { useNewsTabs, useSearch, useGoogleAuth } from "./hooks";
import { toast } from "react-toastify";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import NewsTicker from "./NewsTicker";
import ArrowButton from "../../assets/svg/arrow.svg";
import GoogleIcon from "../Icons/GoogleIcon";
import LogoutIcon from "../Icons/LogoutIcon";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";

function NewsNavbar(props) {
  const location = useLocation();
  const [value, setValue] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const [isSticky, setIsSticky] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);

  const { newsTabs } = useNewsTabs();
  const { handleSearch } = useSearch();
  const { googleLogin } = useGoogleAuth(props.setLogin);

  const isLoggedIn = props.login;

  useEffect(() => {
    const path = location.pathname.split("/").slice(0, 3).join("/");
    const index = newsTabs.findIndex((tab) => tab.path === path);
    setValue(index >= 0 ? index : false);
  }, [location.pathname, newsTabs]);

  useEffect(() => {
    const handleScroll = () => {
      const offset = window.scrollY;
      if (offset > 200) {
        setIsSticky(true);
      } else {
        setIsSticky(false);
      }
    };
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  const handleMenuClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleSearchChange = (event) => {
    const query = event.target.value;
    setSearchQuery(query);
    handleSearch(query);
  };

  const logout = () => {
    localStorage.clear();
    toast.success("Successfully logged out!");
    props.setLogin(false);
  };

  const { onReportClick } = props;

  const handleReportClick = () => {
    handleMenuClose();
    onReportClick(); // Call the function passed from parent component
  };

  return (
    <div className={`flex flex-col w-full items-center bg-grey`}>
      <div
        className={`md:h-[190px] w-full flex flex-col items-center justify-center`}
      >
        <div className="md:h-full flex items-center justify-between md:w-3/4 w-full px-4 md:px-4 bg-white">
          <div className="hidden md:flex flex-col justify-around items-start h-full">
            <input
              type="text"
              value={searchQuery}
              onChange={handleSearchChange}
              placeholder="Search news..."
              className="border rounded-md p-2"
            />
            <div
              className="relative inline-flex items-center justify-center w-48 h-6 mt-2 cursor-pointer"
              onClick={() => (window.location.href = "/")}
            >
              <img
                src={ArrowButton}
                alt="arrow"
                className="absolute inset-0 w-full h-full object-cover"
              />
              <p className="relative text-white z-10 text-xs">
                Back To Main Site
              </p>
            </div>
          </div>
          <div className="flex flex-wrap items-center text-center justify-center gap-2 md:gap-3 lg:gap-4">
            <p className="font-medium text-2xl sm:text-3xl md:text-4xl lg:text-5xl font-jacques_francis">
              Daily Stock Hunt
            </p>
          </div>
          <div className="flex items-center space-x-4">
            {isLoggedIn ? (
              <div className="dropdown">
                <button
                  className="dropdown-toggle btn bg-white text-black px-4 py-1 text-sm rounded-md border border-black"
                  data-bs-toggle="dropdown"
                >
                  {JSON.parse(localStorage.getItem("Username"))}
                </button>
                <ul
                  onClick={logout}
                  className="dropdown-menu hover:bg-hoveredWhite cursor-pointer text-black px-4 py-1 text-sm rounded-md border border-black"
                >
                  <li className="text-sm flex justify-start gap-2 items-center">
                    <LogoutIcon inherit />
                    <button className="cursor-pointer">Logout</button>
                  </li>
                </ul>
              </div>
            ) : (
              <button
                className="bg-white flex space-x-2 text-black px-4 py-1 text-sm rounded-md border border-black"
                onClick={googleLogin}
              >
                <GoogleIcon />
                <div>Login</div>
              </button>
            )}
          </div>
        </div>

        <div className="mt-2 md:w-3/4 w-[100%]">
          <NewsTicker />
        </div>

        <div
          className={`md:w-3/4 w-[95%] border-b bg-white ${
            isSticky ? "sticky-navbar" : ""
          }`}
        >
          <Tabs
            value={value}
            onChange={handleChange}
            variant="scrollable"
            scrollButtons="auto"
            className="tabs-container"
          >
            {newsTabs.map((tab, index) =>
              tab.label === "Markets" ? (
                <div
                  key={index}
                  className="tab-dropdown"
                  style={{
                    display: "inline-flex",
                    alignItems: "center",
                    position: "relative",
                  }}
                >
                  <Tab
                    label={tab.label}
                    component={NavLink}
                    to={tab.path}
                    value={index}
                    sx={{
                      fontFamily: "Merriweather",
                      fontWeight: "800",
                      color: "black",
                    }}
                  />
                  <ArrowDropDownIcon
                    onClick={handleMenuClick}
                    style={{ cursor: "pointer" }}
                  />
                  <Menu
                    anchorEl={anchorEl}
                    open={Boolean(anchorEl)}
                    onClose={handleMenuClose}
                    MenuListProps={{
                      "aria-labelledby": "basic-button",
                    }}
                    anchorOrigin={{
                      vertical: "bottom",
                      horizontal: "left",
                    }}
                    transformOrigin={{
                      vertical: "top",
                      horizontal: "left",
                    }}
                    PaperProps={{
                      style: {
                        width: "135px",
                        left: "50%",
                        transform: "translateX(-50%)",
                      },
                    }}
                  >
                    <MenuItem
                      onClick={handleReportClick}
                      component={NavLink}
                      to="/news-portal/markets/report"
                      sx={{
                        fontFamily: "Merriweather",
                        fontWeight: "400",
                        color: "black",
                        fontSize: "14px",
                      }}
                    >
                      Report
                    </MenuItem>
                  </Menu>
                </div>
              ) : (
                <Tab
                  key={index}
                  label={tab.label}
                  component={NavLink}
                  to={tab.path}
                  value={index}
                  sx={{
                    fontFamily: "Merriweather",
                    fontWeight: "800",
                    color: "black",
                  }}
                />
              )
            )}
          </Tabs>
        </div>
      </div>
    </div>
  );
}

export default NewsNavbar;
