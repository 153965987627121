import { useState, useEffect} from "react";
import { useGoogleLogin } from "@react-oauth/google";
import { fetchNewsTabs, searchNews, googleLoginAPI } from './api';
import { toast } from "react-toastify";
import { generateToken } from "../../utils/generateWatchlistToken";

export const useNewsTabs = () => {
  const [newsTabs, setNewsTabs] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const loadTabs = async () => {
      try {
        const tabs = await fetchNewsTabs();
        setNewsTabs(tabs);
      } catch (error) {
        toast.error("Error fetching news tabs");
      } finally {
        setLoading(false);
      }
    };

    loadTabs();
  }, []);

  return { newsTabs, loading };
};

export const useSearch = () => {
  const [searchResults, setSearchResults] = useState([]);
  const [loading, setLoading] = useState(false);
  const [query, setQuery] = useState("");
  const debouncedQuery = useDebounce(query, 500); // Delay by 500ms

  useEffect(() => {
    const fetchSearchResults = async () => {
      if (debouncedQuery.length > 2) {
        setLoading(true);
        try {
          const results = await searchNews(debouncedQuery); // Fetch search results from API
          setSearchResults(results);
        } catch (error) {
          console.error("Error while fetching search results", error);
        } finally {
          setLoading(false);
        }
      } else {
        setSearchResults([]); // Clear results if query is too short
      }
    };

    fetchSearchResults();
  }, [debouncedQuery]);

  const handleSearch = (query) => setQuery(query);

  return { searchResults, loading, handleSearch };
};

function useDebounce(value, delay) {
  const [debouncedValue, setDebouncedValue] = useState(value);

  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedValue(value);
    }, delay);

    return () => {
      clearTimeout(handler);
    };
  }, [value, delay]);

  return debouncedValue;
}


export const useGoogleAuth = (setIsLoggedIn) => {
  const googleLogin = useGoogleLogin({
    onSuccess: async (response) => {
      try {
        const data = await googleLoginAPI(response.access_token);
        localStorage.setItem("Token", JSON.stringify(data.access_token));
        localStorage.setItem("Username", JSON.stringify(`${data.user.first_name} ${data.user.last_name}`));
        localStorage.removeItem("TempToken");
        generateToken();
        setIsLoggedIn(true);
      } catch (error) {
        toast.error("Something went wrong during login");
      }
    },
  });

  return { googleLogin };
};
