import React, { useState, useEffect, useRef } from "react";

import NewsCard from "../../components/NewsCard/NewsCard";
import axios from "axios";
import { useNavigate, useLocation } from "react-router-dom";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
} from "@mui/material";
import SkeletonNewsCards from "../../components/SkeletonNewsCards";

function CorporateContainer({
  lastRunTime,
  sideMenuCollapsed,
}) {
  const location = useLocation();
  const navigate = useNavigate();
  const searchParams = new URLSearchParams(location.search);
  const tag = searchParams.get("tag");
  const [news, setNews] = useState([]);
  const [tags, setTags] = useState([]);
  const [search, setSearch] = useState("");
  const [page, setPage] = useState(1);
  const [next, setNext] = useState("");
  const [previous, setPrevious] = useState("");
  const [active, setActive] = useState(tag ? tag : "All");
  const [loading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalData, setModalData] = useState(null);
  const [hasMore, setHasMore] = useState(true);
  const [pageSize, setPageSize] = useState(10);

  const [visibleNewsCount, setVisibleNewsCount] = useState(12);

  const loader = useRef(null);
  const observerTarget = useRef(null);
  const pageRef = useRef(page);
  const isMounted = useRef(false);
  //calculating the refresh time 1 hour on Saturday and Sunday, 5 minutes otherwise
  const refreshInterval =
    new Date().getDay() === 0 || new Date().getDay() === 6
      ? 60 * 60 * 1000
      : 5 * 60 * 1000;

  useEffect(() => {
    localStorage.removeItem("currentPage");
  }, []);

  useEffect(() => {
    const interval = setInterval(() => {
      getCommonNews();
    }, refreshInterval);
    return () => clearInterval(interval);
  }, []);

  const newsCache = {};

  const getCommonNews = async (tag, currentPage = 1) => {
    try {
      setLoading(true);

      const url =
        tag?.length > 0
          ? `/api/news-feed?tag=${tag}&page_size=8&page=${currentPage}`
          : `/api/news-feed?page=${currentPage}&page_size=${pageSize}`;

      const response = await axios.get(process.env.REACT_APP_BACKEND_URL + url);

      if (response.data) {
        const responseData = {
          news: [...response.data.results.news],
          tags: [...response.data.results.new_news_tags],
          next: response.data.next,
          previous: response.data.previous,
        };
        newsCache[currentPage] = responseData;
        setNews((prevNews) => {
          const newData = response.data.results.news;
          const uniqueNews = newData.filter((newItem) => {
            return !prevNews.find(
              (item) =>
                item.title === newItem.title &&
                item.published_datetime === newItem.published_datetime
            );
          });
          return [...uniqueNews, ...prevNews];
        });
        setTags([...response.data.results.new_news_tags]);
        setNext(response.data.next);
        setPrevious(response.data.previous);
        setLoading(false);
        localStorage.setItem("currentPage", currentPage);
      } else {
        console.error("Unexpected response:", response.data);
        setLoading(false);
      }
    } catch (error) {
      console.error("Error fetching news:", error);
      setLoading(false);
    }
  };

  const onClickSearch = (val) => {
    setPage(1);
    const queryParams = new URLSearchParams();
    if (val?.length > 0) {
      queryParams.set("tag", val);
    } else if (search.length > 0) {
      queryParams.set("tag", search);
    }
    setNews([]);
    navigate(`/news?${queryParams.toString()}`);
  };

  const removeQueryParams = () => {
    const queryParams = new URLSearchParams();
    setSearch("");
    setActive("All");
    setPage(1);
    queryParams.delete("tag");
    navigate(`/news?${queryParams.toString()}`);

    getCommonNews();
  };

  //Handler Function when the next button is clicked
  let isFetchingData = false;

  const getNextData = () => {
    if (isFetchingData) {
      return; // Prevent multiple simultaneous calls
    }

    isFetchingData = true;

    const nextPage = currentPage + 1;
    setLoading(true);

    axios
      .get(
        process.env.REACT_APP_BACKEND_URL +
        `/api/news-feed?page=${currentPage}&page_size=${pageSize}`
      )
      .then((res) => {
        if (res.data) {
          const newData = res.data.results.news;

          // Check for duplicates based on the title before adding new data
          const filteredData = newData.filter(
            (item) =>
              !news.some((existingItem) => existingItem.title === item.title)
          );

          setNews((prevNews) => [...prevNews, ...filteredData]);
          setNext(res.data.next);
          setPrevious(res.data.previous);
          setCurrentPage(nextPage);
          localStorage.setItem("currentPage", nextPage);
          setLoading(false);
        }

        isFetchingData = false; // Reset the flag after the request is completed
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
        isFetchingData = false; // Reset the flag in case of an error
      });
  };

  //Handler Function when the previous button is clicked
  const getPreviousData = (url) => {
    axios
      .get(process.env.REACT_APP_BACKEND_URL + url.substring(21))
      .then((res) => {
        if (res.data) {
          setNews([...res.data.results.news]);
          setNext(res.data.next);
          setPrevious(res.data.previous);

          // Update the current page
          setCurrentPage((prevPage) => prevPage - 1);

          // Using the updated currentPage value when saving to localStorage
          localStorage.setItem("currentPage", currentPage - 1);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const dateFormat = (lastRunDate) => {
    const date = new Date(lastRunDate);

    // Extract date components
    const day = date.getDate().toString().padStart(2, "0");
    const month = (date.getMonth() + 1).toString().padStart(2, "0");
    const year = date.getFullYear().toString();

    // Extract time components
    let hour = date.getHours();
    const minute = date.getMinutes();
    const amPm = hour >= 12 ? "PM" : "AM";

    // Convert hour to 12-hour format
    hour = hour % 12 || 12;

    // Format the date and time
    const formattedDate = `${day}/${month}/${year}`;
    const formattedTime = `${hour.toString().padStart(2, "0")}:${minute
      .toString()
      .padStart(2, "0")} ${amPm}`;
    return `${formattedDate}, ${formattedTime}`;
  };

  const openModal = (data) => {
    setModalData(data);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  function XmlDataModal({ data, closeModal }) {
    return (
      <>
        <Dialog open={isModalOpen} onClose={closeModal}>
          <DialogTitle>{data?.xml_tags?.NSESymbol}</DialogTitle>
          <DialogContent>
            {data?.xml_tags_subject ? (
              <div
                dangerouslySetInnerHTML={{ __html: data?.xml_tags_subject }}
              />
            ) : (
              Object.entries(data?.xml_tags).map(([key, value]) => (
                <div key={key}>
                  <strong>{key}:</strong> {value}
                </div>
              ))
            )}
            <div>
              <strong>Summary:</strong>
              <div dangerouslySetInnerHTML={{ __html: data?.summary }} />
            </div>
            {data?.link && (
              <div className="mt-4">
                <a href={data.link} target="_blank" rel="noopener noreferrer">
                  <strong className="text-blue-700 ">
                    Click here to know more
                  </strong>
                </a>
              </div>
            )}
          </DialogContent>
          <DialogActions>
            <Button onClick={closeModal} color="primary">
              Close
            </Button>
          </DialogActions>
        </Dialog>
      </>
    );
  }

  const handleViewMore = () => {
    setVisibleNewsCount((prevCount) => prevCount + 5); // Show 5 more news items on each click
  };

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        if (entries[0].isIntersecting) {
          getNextData();
        }
      },
      { threshold: 1 }
    );

    if (observerTarget.current) {
      observer.observe(observerTarget.current);
    }

    return () => {
      if (observerTarget.current) {
        observer.unobserve(observerTarget.current);
      }
    };
  }, [currentPage]);

  return (
    <>
      <div className="bg-white rounded p-2 w-full flex">
        <div className=" flex flex-col justify-between w-full mb-4">
          <div className="top-row flex justify-between">
            <div className="flex flex-col justify-start">
              <h5
                className="text-xl font-semibold text-primaryHovered cursor-pointer mb-2"
                onClick={removeQueryParams}
              >
                Real Time Market News
              </h5>
              <span className="text-xs text-slate-700">
                {lastRunTime ? dateFormat(lastRunTime) : null}
              </span>
            </div>
          </div>

          <div className="flex flex-wrap justify-start items-center gap-0 w-full">
            {news.length > 0 ? (
              news
                .slice(0, visibleNewsCount)
                .map((data, index) => (
                  <NewsCard
                    data={data}
                    key={index}
                    openModal={openModal}
                    closeModal={closeModal}
                    cardHeight={110}
                    sideMenuCollapsed={sideMenuCollapsed}
                    percentage={true}
                    showImages={false}
                  />
                ))
            ) : (
              <div className="flex flex-wrap items-center justify-between">
                {loading && <SkeletonNewsCards count={12} height={110} />}
              </div>
            )}
          </div>

          {visibleNewsCount < news.length && (
            <div className="flex justify-center mt-4">
              <Button
                variant="contained"
                onClick={handleViewMore}
                color="primary"
              >
                View More
              </Button>
            </div>
          )}

        </div>

        <div ref={observerTarget}></div>
      </div>

      {isModalOpen && <XmlDataModal data={modalData} closeModal={closeModal} />}
    </>
  );
}

export default CorporateContainer;
