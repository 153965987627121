import React, { useState, useEffect, useRef } from "react";
import {
  IconButton,
  Badge,
  Popover,
  Typography,
  List,
  Divider,
} from "@mui/material";
import axios from "axios";
import { formatDate } from "../../utils/utils";
import Tag from "../NewsCard/Tag";
import Bell from "../Icons/Bell";

function CorporateNotificationPanel({ isLoggedIn }) {
  const [anchorEl, setAnchorEl] = useState(null);
  const [notifications, setNotifications] = useState([]);
  const [isNewNotification, setIsNewNotification] = useState(false);
  const [showNotificationBadge, setShowNotificationBadge] = useState(false);

  const refreshTiming =
    new Date().getDay() === 0 || new Date().getDay() === 6
      ? 60 * 60 * 1000
      : 5 * 60 * 1000;

  const prevNotificationsRef = useRef([]);

  useEffect(() => {
    // Fetch notifications when the component initially mounts
    fetchNotifications();

    // Interval to fetch notifications
    const refreshInterval = setInterval(() => {
      fetchNotifications();
    }, refreshTiming);

    // Clean up the interval when the component unmounts
    return () => clearInterval(refreshInterval);
  }, [isLoggedIn]);

  const openNotificationPanel = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const closeNotificationPanel = () => {
    setAnchorEl(null);
  };

  const apiHost = process.env.REACT_APP_BACKEND_URL;

  // Function to fetch and update notifications
  const fetchNotifications = async () => {
    try {
      const apiUrl = apiHost + "/auth/notifications";

      const headers = {};
      // If the user is logged in, add the bearer token to the request headers
      if (isLoggedIn) {
        headers.Authorization = `Bearer ${JSON.parse(
          localStorage.getItem("Token")
        )}`;
      }

      const response = isLoggedIn
        ? await axios(apiUrl, { headers }) // When the user is logged in
        : await axios(apiUrl); // When the user is not logged in

      if (response.data) {
        const newNotifications = Array.isArray(response.data) ? response.data : [];

        // Check if there are new notifications based on unique identifiers (e.g., id)
        const isNew = !arraysAreEqual(
          prevNotificationsRef.current,
          newNotifications
        );

        // Set notifications in the state and update the previous notifications
        setNotifications(newNotifications);
        prevNotificationsRef.current = newNotifications;
      } else {
        console.error("Unexpected response:", response?.data);
      }
    } catch (error) {
      console.error("Error fetching notifications:", error);
    }
  };

  // Helper function to compare arrays of notification objects based on unique identifiers
  function arraysAreEqual(arr1, arr2) {
    if (!Array.isArray(arr1) || !Array.isArray(arr2)) {
      return false;
    }

    if (arr1.length !== arr2.length) {
      return false;
    }

    const uniqueIds1 = new Set(arr1.map((item) => item.id));
    const uniqueIds2 = new Set(arr2.map((item) => item.id));

    return [...uniqueIds1].every((id) => uniqueIds2.has(id));
  }

  const notificationList =
    notifications.length > 0 &&
    notifications.map((notification, index) => (
      <div
        className="notification-item h-[85px] w-full cursor-pointer border-t p-2 hover:bg-white shadow-sm border-2 rounded mb-1"
        key={index}
      >
        <a
          className="flex flex-col"
          href={notification.link}
          target="_blank"
          rel="noopener noreferrer"
        >
          <span className="font-semibold text-sm">
            {notification.title.length > 30
              ? notification.title.slice(0, 30) + "..."
              : notification.title}
          </span>
          <div>
            {notification.description && formatDate(notification.description)}
          </div>
          <div>
            {notification.tags?.map((tag, index) => (<>
              <Tag data={tag} key={index} fullLength={true} />
              </>
            ))} 
          </div>
        </a>
      </div>
    ));

  return (
    <div
      className="notification-container hidden rounded-lg lg:flex lg:w-1/4 w-full mt-3.5"
      onClick={() => {
        setShowNotificationBadge(false);
      }}
    >
      <div
        open={true}
        anchorEl={anchorEl}
        onClose={closeNotificationPanel}
        PaperProps={{
          sx: {
            borderRadius: "16px",
            width: "300px", 
          },
        }}
      >
        <div className="min-width-300 bg-lightPink px-2 left-10">
          <Typography variant="h6" className="p-3 flex items-center pb-1">
            Notifications
          </Typography>

          {notifications.length === 0 ? (
            <Typography className="p-3">No new notifications</Typography>
          ) : (
            <>
              <List>{notificationList}</List>
            </>
          )}
        </div>
      </div>
    </div>
  );
}

export default CorporateNotificationPanel;
