import React from "react";

const CustomChip = ({
  title,
  selected,
  onClick,
  smallText,
  useMerriweatherFont,
}) => {
  return (
    <div className="flex item-start gap-0">
      <div
        className={`px-3 py-1 rounded-full font-semibold cursor-pointer ${
          selected
            ? "bg-secondaryBrown text-white"
            : "bg-white border border-gray-200"
        } ${useMerriweatherFont ? "font-merriweather" : ""}`}
        onClick={() => onClick(title)}
        style={useMerriweatherFont ? { fontFamily: "Merriweather" } : {}}
      >
        <p className={`${useMerriweatherFont ? "font-merriweather" : ""}`}>{title}</p>
      </div>
      {smallText && (
        <span className="text-xs ml-[-10px] text-white bg-lime-700 w-5 h-5 rounded-full flex items-center justify-center">
          {smallText}
        </span>
      )}
    </div>
  );
};

export default CustomChip;
