import React from "react";

const LogoutIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="28"
      height="28"
      viewBox="0 0 28 28"
      fill="none"
    >
      <g clipPath="url(#clip0_359_5312)">
        <path
          d="M5.44423 4.66664H17.8887V12.2889H19.4442V4.66664C19.4442 4.25408 19.2803 3.85842 18.9886 3.5667C18.6969 3.27497 18.3012 3.11108 17.8887 3.11108H5.44423C5.03167 3.11108 4.63601 3.27497 4.34428 3.5667C4.05256 3.85842 3.88867 4.25408 3.88867 4.66664V23.3333C3.88867 23.7459 4.05256 24.1415 4.34428 24.4332C4.63601 24.725 5.03167 24.8889 5.44423 24.8889H17.8887C18.3012 24.8889 18.6969 24.725 18.9886 24.4332C19.2803 24.1415 19.4442 23.7459 19.4442 23.3333H5.44423V4.66664Z"
          fill="#3F4852"
        />
        <path
          d="M21.9022 13.44C21.7535 13.3126 21.5621 13.246 21.3663 13.2536C21.1706 13.2611 20.9849 13.3423 20.8464 13.4808C20.7078 13.6193 20.6267 13.805 20.6191 14.0007C20.6116 14.1965 20.6782 14.3879 20.8056 14.5367L23.4345 17.1111H12.1567C11.9504 17.1111 11.7526 17.1931 11.6067 17.3389C11.4609 17.4848 11.3789 17.6826 11.3789 17.8889C11.3789 18.0952 11.4609 18.293 11.6067 18.4389C11.7526 18.5847 11.9504 18.6667 12.1567 18.6667H23.4345L20.8056 21.3578C20.7242 21.4275 20.658 21.5133 20.6113 21.6098C20.5647 21.7063 20.5384 21.8114 20.5343 21.9185C20.5301 22.0256 20.5482 22.1325 20.5873 22.2323C20.6264 22.3321 20.6857 22.4227 20.7615 22.4985C20.8373 22.5743 20.928 22.6336 21.0278 22.6727C21.1276 22.7118 21.2344 22.7299 21.3415 22.7257C21.4486 22.7216 21.5537 22.6954 21.6502 22.6487C21.7467 22.602 21.8325 22.5359 21.9022 22.4545L26.4445 17.9433L21.9022 13.44Z"
          fill="#3F4852"
        />
      </g>
      <defs>
        <clipPath id="clip0_359_5312">
          <rect width="28" height="28" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default LogoutIcon;
