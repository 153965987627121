import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

const tempToken = localStorage.getItem("TempToken");

const AdvanceAndDeclines = () => {
  const navigate = useNavigate();
  const [data, setData] = useState([]);
  const [totalAdvanced, setTotalAdvanced] = useState(0);
  const [totalDeclined, setTotalDeclined] = useState(0);
  const [error, setError] = useState("");
  const [isLoading, setIsLoading] = useState(true);

  const fetchAdvDeclData = async () => {
    setIsLoading(true);
    const watchlistToken =
      tempToken || JSON.parse(localStorage.getItem("WatchlistToken"));
    const url = `https://stock.accordwebservices.com/BSEStock/GetAdvancesnDeclines?ExCode=BE49H6S&Group=&Top=&PageNo=1&Pagesize=10&SortExpression=ADV&SortDirection=Desc&token=${watchlistToken}`;
    try {
      const response = await fetch(url);
      const jsonData = await response.json();
      // console.log("Advance--", jsonData.Table);

      if (jsonData && jsonData.Table) {
        setData(jsonData.Table);
        let totalAdv = 0;
        let totalDec = 0;
        jsonData.Table.forEach((item) => {
          totalAdv += Number(item.ADV);
          totalDec += Number(item.DEC);
        });
        setTotalAdvanced(totalAdv);
        setTotalDeclined(totalDec);
      } else {
        setError("No data found");
      }
    } catch (error) {
      setError("Failed to fetch data: " + error.message); // More descriptive error
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchAdvDeclData();
  }, []);

  const viewMore = () => {
    navigate("/news-portal/markets", { state: { showAdvDeclData: true } });
  };

  const renderProgressBarSkeleton = () => {
    return (
      <div className="flex w-full flex-col animate-pulse">
        <div className="flex w-full items-center">
          <div className="bg-gray-300 h-2.5 rounded-md w-full" />
        </div>
      </div>
    );
  };

  const renderProgressBar = () => {
    const total = totalAdvanced + totalDeclined;
    const advPercent = (totalAdvanced / total) * 100;
    const decPercent = (totalDeclined / total) * 100;

    return (
      <div className="flex w-full flex-col">
        <div className="flex w-full items-center">
          <div
            style={{ width: `${advPercent}%` }}
            className="bg-[#198753] h-2.5 rounded-l-md"
          />
          <div
            style={{ width: `${decPercent}%` }}
            className="bg-red-500 h-2.5 rounded-r-md ml-0.5"
          />
        </div>
        <div className="flex w-full justify-between mt-0.5 text-sm">
          <span className="text-[#198753]">{totalAdvanced}</span>
          <span className="text-red-500">{totalDeclined}</span>
        </div>
      </div>
    );
  };

  return (
    <div className="bg-white shadow rounded mt-2 px-2 py-2 lg:ml-2 lg:w-[97%] font-merriweather">
      <p className="text-lg font-bold my-2 font-merriweather border-b-2 border-gray-400">
        Advance / Decline (BSE)
      </p>
      {error && <p className="text-red-500">{error}</p>}
      {isLoading ? renderProgressBarSkeleton() : renderProgressBar()}
      <div className="flex justify-end mt-2">
        <button
          onClick={viewMore}
          className="text-blue-500 hover:text-blue-700 text-sm transition duration-200"
        >
          View More
        </button>
      </div>
    </div>
  );
};

export default AdvanceAndDeclines;
