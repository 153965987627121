import React, { useEffect, useState } from "react";
import { Skeleton } from "@mui/material";
import { useNavigate } from "react-router-dom";
import {
  ContentPasteSearchTwoTone,
  ThumbDownTwoTone,
  ThumbUpTwoTone,
  ThumbDown,
  ThumbUp,
} from "@mui/icons-material";
import axios from "axios";
import { toast } from "react-toastify"; // Make sure to install react-toastify
import { apiUrl } from "../utils/utils";
import IPODetailNewsPortalModal from "../pages/IPO Detail/IPODetailNewsPortalModal";
import HistoricDataNewsPortal from "../pages/HistoricData/HistoricDataNewsPortal";
import MarketSearchNewsPortal from "./MarketSearchNewsPortal";

export default function NewsPortalCustomTable({
  headers,
  rowData,
  type,
  loading,
  IPO,
  ipoHeader,
  filterClick,
  orderingStatus,
  useMerriweatherFont,
  isLoggedIn,
}) {
  const navigate = useNavigate();
  const token = JSON.parse(localStorage.getItem("Token"));
  const [isModalOpen, setModalOpen] = useState(false);

  const [selectedCompanyName, setSelectedCompanyName] = useState("");

  const [data, setData] = useState(rowData);
  const [marketSearchData, setMarketSearchData] = useState(null);
  // Update state when rowData changes
  useEffect(() => {
    setData(rowData);
  }, [rowData]);
  useEffect(() => {
    if (marketSearchData) {
      navigate(`/news-portal/markets`);
    }
  }, [marketSearchData, navigate]);

  const getRowColor = (index, rowData) => {
    if (type === "increase") {
      return index % 2 !== 0 ? "bg-successLight" : "bg-white";
    } else if (type === "decrease") {
      return index % 2 !== 0 ? "bg-errorLight" : "bg-white";
    } else if (IPO) {
      const currentDate = new Date();
      currentDate.setUTCHours(0, 0, 0, 0); // Set time to midnight

      const listingDate = parseDateString(rowData[index]?.listing_date);
      const closeDate = parseDateString(rowData[index]?.close_date);
      const openDate = parseDateString(rowData[index]?.open_date);

      // If listing date is today, apply dark color
      if (isSameDay(listingDate, currentDate)) {
        return "bg-green-600 font-semibold text-white";
      }
      // If listing soon, apply light color
      else if (currentDate > closeDate && currentDate < listingDate) {
        return "bg-green-100";
      }
      // If IPO Open, apply highlighted tone
      else if (currentDate >= openDate && currentDate <= closeDate) {
        return "bg-yellow-200";
      }
      // For other cases, default to white background
      else {
        return "bg-white";
      }
    } else {
      return index % 2 !== 0 ? "bg-slate-100" : "bg-white";
    }
  };

  // Function to parse date string
  const parseDateString = (dateString) => {
    const [monthStr, day, year] = dateString.split(" ");
    const monthIndex = {
      Jan: 0,
      Feb: 1,
      Mar: 2,
      Apr: 3,
      May: 4,
      Jun: 5,
      Jul: 6,
      Aug: 7,
      Sep: 8,
      Oct: 9,
      Nov: 10,
      Dec: 11,
    }[monthStr];
    return new Date(Date.UTC(year, monthIndex, parseInt(day)));
  };

  // Function to check if two dates are the same day
  const isSameDay = (date1, date2) => {
    return (
      date1.getFullYear() === date2.getFullYear() &&
      date1.getMonth() === date2.getMonth() &&
      date1.getDate() === date2.getDate()
    );
  };



  const handleIssuerCompanyClick = (id, fincode, symbol, issuer_company) => {
    console.log("Fincode:---->uppar", fincode);
    setMarketSearchData({ id, fincode, symbol, issuer_company });
    console.log("Fincode:---->niche", fincode);
  };
  console.log("MarketSearchData:---->", marketSearchData);

  const closeModal = () => {
    setModalOpen(false);
    setSelectedCompanyName("");
  };

  const handleInfoIconClick = (issuerCompanyName) => {
    setSelectedCompanyName(issuerCompanyName); // Set the selected company name
    setModalOpen(true); // Open the modal
  };

  const handleReactionClick = async (id, currentReaction, index) => {
    if (!token) {
      toast.error("You need to be logged in to perform this action");
      return;
    }

    try {
      let response;

      response = await axios.post(
        `${apiUrl}/api/reaction/`,
        {
          id,
          content_type: "ipo",
          reaction: currentReaction,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (response.status === 200) {
        const updatedData = [...data];
        if (currentReaction === "like") {
          if (updatedData[index].user_reaction === "dislike") {
            updatedData[index].dislikes -= 1;
          }
          updatedData[index].likes += 1;
          updatedData[index].user_reaction = "like";
        } else if (currentReaction === "dislike") {
          if (updatedData[index].user_reaction === "like") {
            updatedData[index].likes -= 1;
          }
          updatedData[index].dislikes += 1;
          updatedData[index].user_reaction = "dislike";
        } else {
          if (updatedData[index].user_reaction === "like") {
            updatedData[index].likes -= 1;
          } else if (updatedData[index].user_reaction === "dislike") {
            updatedData[index].dislikes -= 1;
          }
          updatedData[index].user_reaction = null;
        }
        setData(updatedData);
        // toast.success("Reaction saved!");
      }
    } catch (error) {
      console.error("Error updating reaction", error);
      toast.error("Failed to update reaction");
    }
  };

  return (
    <div className="my-2">
      <div className="pl-0">
        <table className="table rounded">
          <thead className="thead-dark bg-primaryHovered text-white rounded">
            <tr
              className={`font-bold ${
                useMerriweatherFont ? "font-merriweather" : ""
              }`}
            >
              {ipoHeader
                ? headers?.map((title, index) => (
                    <th
                      key={index}
                      className={`header text-center  p-1 ${
                        index !== headers.length - 1
                          ? "border-r border-gray-300 font"
                          : ""
                      }`}
                      scope="col"
                    >
                      {title === "Open Date" ||
                      title === "Close Date" ||
                      title === "% Change" ||
                      title === "Listing Date" ? (
                        <button
                          className={`flex justify-between items-center px-0 w-[70px] ${
                            useMerriweatherFont ? "font-merriweather" : ""
                          }`}
                          onClick={() =>
                            filterClick(title.toLowerCase().replace(" ", "_"))
                          }
                        >
                          {title}
                          {orderingStatus ===
                          title.toLowerCase().replace(" ", "_") ? (
                            <span className="h-4"> ▼</span>
                          ) : orderingStatus ===
                            `-${title.toLowerCase().replace(" ", "_")}` ? (
                            <span className="h-4"> ▲</span>
                          ) : (
                            <div className="flex flex-col">
                              <span className="h-4 text-gray-400"> ▲</span>
                              <span className="h-4 text-gray-400"> ▼</span>
                            </div>
                          )}
                        </button>
                      ) : title === "Issuer Company" ? (
                        <>
                          <a
                            className={`${
                              useMerriweatherFont ? "font-merriweather" : ""
                            }`}
                          >
                            {title}
                          </a>
                        </>
                      ) : (
                        <p
                          className={`${
                            useMerriweatherFont ? "font-merriweather" : ""
                          }`}
                        >
                          {title}
                        </p>
                      )}
                    </th>
                  ))
                : headers?.map((title, index) => (
                    <th
                      key={index}
                      className={`header ${
                        useMerriweatherFont ? "font-merriweather" : ""
                      }`}
                      scope="col"
                    >
                      {title}
                    </th>
                  ))}
              {/* Add new column header for Info Icon */}
              {IPO && (
                <th
                  className="header text-center border-l border-r"
                  scope="col"
                >
                  <ContentPasteSearchTwoTone />
                </th>
              )}
            </tr>
          </thead>
          <tbody>
            {data.length === 0 || loading ? (
              loading ? (
                Array.from({ length: 4 }, (_, rowIndex) => (
                  <tr key={rowIndex}>
                    {headers?.map((_, colIndex) => (
                      <td key={colIndex}>
                        <Skeleton
                          animation="wave"
                          variant="rect"
                          height={20}
                          width={70}
                        />
                      </td>
                    ))}
                    {/* Add an empty cell for the new Info column */}
                    <td className="border">
                      <Skeleton
                        animation="wave"
                        variant="rect"
                        height={20}
                        width={20}
                      />
                    </td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td
                    colSpan={headers?.length + (IPO ? 1 : 0)}
                    className="text-center"
                  >
                    No data available
                  </td>
                </tr>
              )
            ) : (
              data.map((row, index) => {
                const rowColor = getRowColor(index, data);
                const isListingToday =
                  rowColor === "bg-green-600 font-semibold text-white";

                return (
                  <tr
                    key={index}
                    className={`${rowColor} text-base border-l border-r ${
                      useMerriweatherFont ? "font-merriweather" : ""
                    }`}
                  >
                    {Object.keys(row).map((key, columnIndex) => {
                      if (
                        key === "likes" ||
                        key === "dislikes" ||
                        key === "id" ||
                        key === "user_reaction"
                      ) {
                        return null; // Skip rendering likes and dislikes as separate columns
                      }
                      return (
                        <td
                          key={key}
                          className={`${
                            IPO && key === "percentageChange"
                              ? "text-center border"
                              : ""
                          } ${
                            key === "percentageChange" &&
                            `${
                              row.percentageChange > 0
                                ? "text-success font-semibold ps-3"
                                : row.percentageChange < 0
                                ? "text-error font-semibold"
                                : "text-black"
                            }`
                          } ${
                            IPO && columnIndex !== headers.length - 1
                              ? "border-r border-gray-300"
                              : ""
                          } ${
                            isListingToday &&
                            (key === "percentageChange" ? "text-white" : "")
                          }`}
                        >
                          {key === "issuer_company" ? (
                            <div className="flex justify-between items-center">
                              <button
                                disabled={row?.current_price === "-"}
                                className={`${
                                  row?.current_price !== "-" &&
                                  (isListingToday
                                    ? "text-white underline "
                                    : "text-blue-500")
                                } `}
                                onClick={() =>
                                  handleIssuerCompanyClick(
                                    row?.id,
                                    row?.issuer_company?.fincode,
                                    row?.issuer_company?.symbol,
                                    row?.issuer_company
                                  )
                                }
                              >
                                <div className="text-left">
                                  <p
                                    className={`${
                                      useMerriweatherFont
                                        ? "font-merriweather"
                                        : ""
                                    }`}
                                  >
                                    {row?.issuer_company?.name}
                                  </p>
                                </div>
                              </button>
                              {IPO && (
                                <div className="flex flex-col items-end">
                                  <button
                                    onClick={() =>
                                      handleReactionClick(
                                        row?.id,
                                        row?.user_reaction === "like"
                                          ? "null"
                                          : "like",
                                        index
                                      )
                                    }
                                    className={`text-green-500 ${
                                      row?.user_reaction === "like"
                                        ? isListingToday
                                          ? "text-white"
                                          : "text-green-500"
                                        : "text-gray-500"
                                    }`}
                                  >
                                    <span
                                      className={`flex items-center gap-1 ${
                                        isListingToday && "text-white"
                                      }`}
                                    >
                                      {row?.user_reaction === "like" ? (
                                        <ThumbUp fontSize="small" />
                                      ) : (
                                        <ThumbUpTwoTone fontSize="small" />
                                      )}
                                      {row?.likes || 0}
                                    </span>
                                  </button>
                                  <button
                                    onClick={() =>
                                      handleReactionClick(
                                        row?.id,
                                        row?.user_reaction === "dislike"
                                          ? "null"
                                          : "dislike",
                                        index
                                      )
                                    }
                                    className={`text-red-500 ${
                                      row?.user_reaction === "dislike"
                                        ? isListingToday
                                          ? "text-white"
                                          : "text-red-500"
                                        : "text-gray-500"
                                    }`}
                                  >
                                    <span
                                      className={`flex items-center gap-1 ${
                                        isListingToday && "text-white"
                                      }`}
                                    >
                                      {row?.user_reaction === "dislike" ? (
                                        <ThumbDown fontSize="small" />
                                      ) : (
                                        <ThumbDownTwoTone fontSize="small" />
                                      )}
                                      {row?.dislikes || 0}
                                    </span>
                                  </button>
                                </div>
                              )}
                            </div>
                          ) : (
                            row[key]
                          )}
                        </td>
                      );
                    })}
                    {/* Add the Info icon column */}
                    {IPO && (
                      <td className="text-center border">
                        <button
                          onClick={() =>
                            handleInfoIconClick(row?.issuer_company?.name)
                          }
                          className={`${
                            isListingToday ? "text-white" : "text-blue-500"
                          }`}
                        >
                          <ContentPasteSearchTwoTone />
                        </button>
                      </td>
                    )}
                  </tr>
                );
              })
            )}
          </tbody>
        </table>
      </div>
      <IPODetailNewsPortalModal
        isOpen={isModalOpen}
        onClose={closeModal}
        ipo_name={selectedCompanyName}
      />
      {marketSearchData && (
        <MarketSearchNewsPortal
          rowFincode={marketSearchData.fincode}
          rowSymbol={marketSearchData.symbol}
        />
      )}
    </div>
  );
}
