import React, { useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Virtual, Navigation, Pagination } from "swiper/modules";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";

import { KeyboardArrowLeft, KeyboardArrowRight } from "@mui/icons-material";
import { formatDate } from "../utils/utils";

const Carousel = ({ data, carouselKey, cardHeight, cardWidth }) => {
  const [activeIndex, setActiveIndex] = useState(0);

  const sliderSettings = {
    240: {
      slidesPerView: 1.5,
      spaceBetween: 30,
    },
    680: {
      slidesPerView: 2,
      spaceBetween: 30,
    },
    1024: {
      slidesPerView: 3,
      spaceBetween: 30,
    },
  };

  const truncateText = (text, maxLength) => {
    if (text) {
      const words = text.split(" ");
      if (words.length > maxLength) {
        const truncated = words.slice(0, maxLength).join(" ") + "...";
        return truncated;
      }
    }
    return text;
  };

  return (
    <div className="relative px-3 w-full ">
      <Swiper
        key={carouselKey}
        breakpoints={sliderSettings}
        modules={[Virtual, Navigation, Pagination]}
        slidesPerView={3}
        spaceBetween={20}
        navigation={{
          nextEl: `.arrow-right-${carouselKey}`,
          prevEl: `.arrow-left-${carouselKey}`,
        }}
        virtual
        onRealIndexChange={(element) => setActiveIndex(element.activeIndex)}
      >
        {data?.map((item, index) => (
          <SwiperSlide key={index} virtualIndex={index}>
            {item?.link ? (
              <a href={item.link} target="_blank" rel="noreferrer">
                <div
                  className={`border p-2 ${
                    cardWidth ? `h-${cardWidth}` : "h-40"
                  } ${
                    cardHeight ? `h-${cardHeight}` : "h-40"
                  } flex flex-col gap-2 bg-white rounded-md`}
                >
                  <p className="text-sm">{item?.source}</p>
                  <h3 className="text-md font-semibold h-[200px]">
                    {truncateText(item?.title, 10)}
                  </h3>
                  <p>{formatDate(item?.published)}</p>
                </div>
              </a>
            ) : (
              <div
                className={`border p-2 ${cardWidth ? `w-${cardWidth}` : ""} ${
                  cardHeight ? `h-${cardHeight}` : "h-40"
                } flex flex-col gap-2 bg-white rounded-md`}
              >
                <p className="text-sm">{item?.source}</p>
                <h3 className="text-md font-semibold h-[200px]">
                  {truncateText(item?.title, 10)}
                </h3>
                <p>{formatDate(item?.published)}</p>
              </div>
            )}
          </SwiperSlide>
        ))}
      </Swiper>

      <div
        className={`arrow-right-${carouselKey} ${
          activeIndex === data.length - 1 ? "hidden" : ""
        } absolute p-1 -right-5 top-16 z-10 bg-slate-100 cursor-pointer rounded-full`}
      >
        <KeyboardArrowRight />
      </div>

      <div
        className={`arrow-left-${carouselKey} ${
          activeIndex === 0 ? "hidden" : ""
        } absolute align-text-center -left-5 top-16 z-10 bg-slate-100 cursor-pointer p-1 flex items-center justify-center rounded-full`}
      >
        <KeyboardArrowLeft />
      </div>
    </div>
  );
};

export default Carousel;
