import React, { useEffect, useState } from "react";
import { Line } from "react-chartjs-2";
import "chart.js/auto";

const PriceChart = () => {
  const [chartData, setChartData] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState("");

  const tempToken =
    localStorage.getItem("TempToken") ??
    JSON.parse(localStorage.getItem("WatchlistToken"));

  const fetchChartData = async () => {
    const url = `https://stock.accordwebservices.com/BSEStock/GetChartData?ExCode=BE49H6S&Type=H&FINCODE=500112&DateOption=M&DateCount=1&StartDate=&EndDate=&token=${tempToken}`;
    try {
      const response = await fetch(url);
      const { Data } = await response.json();
      console.log(Data);
      const chartLabels = Data.map((data) => data.Date);
      const chartPrices = Data.map((data) => data.price);

      setChartData({
        labels: chartLabels,
        datasets: [
          {
            label: "Stock Price",
            data: chartPrices,
            borderColor: "rgb(75, 192, 192)",
            tension: 0.1,
          },
        ],
      });
    } catch (error) {
      setError("Failed to fetch chart data");
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchChartData();
  }, []);

  if (isLoading) return <div>Loading...</div>;
  if (error) return <div>Error: {error}</div>;

  return (
    <div className="bg-white shadow rounded mt-3.5 px-2 py-2 lg:ml-2 lg:w-[97%] font-merriweather">
      <Line data={chartData} />
    </div>
  );
};

export default PriceChart;
