import React from "react";

const SkeletonElement = ({ className }) => (
  <div className={`${className} animate-pulse bg-gray-300 rounded`}></div>
);

const NewsSkeleton = () => {
  return (
    <div className="grid grid-cols-1 lg:grid-cols-2 gap-2">
      {/* Left Column for Latest News */}
      <div className="lg:w-[100%] w-full bg-white shadow rounded px-2 py-2">
        <SkeletonElement className="h-6 w-3/4 my-2" />
        <div className="space-y-2 pb-4 relative">
          {Array.from({ length: 7 }).map((_, index) => (
            <div
              key={index}
              className="space-y-2 flex items-start relative pl-6"
            >
              <SkeletonElement className="absolute left-0 top-1 w-3 h-3 rounded-full" />
              <div>
                <SkeletonElement className="h-3 w-24 mb-1" />
                <SkeletonElement className="h-4 w-full" />
              </div>
            </div>
          ))}
        </div>
        <SkeletonElement className="h-4 w-1/4 ml-auto" />
      </div>

      {/* Right Column for Prime News */}
      <div className="w-full bg-white shadow rounded px-2 py-2">
        <SkeletonElement className="h-6 w-3/4 my-2" />
        <SkeletonElement className="h-48 w-full rounded mb-3" />
        <SkeletonElement className="h-3 w-1/4 mb-1" />
        <SkeletonElement className="h-4 w-full mb-4" />

        <div className="space-y-4 pb-4">
          {Array.from({ length: 3 }).map((_, index) => (
            <div key={index} className="mb-2">
              <SkeletonElement className="h-3 w-1/4 mb-1" />
              <SkeletonElement className="h-4 w-full" />
            </div>
          ))}
        </div>
        <SkeletonElement className="h-4 w-1/4 ml-auto" />
      </div>
    </div>
  );
};

export default NewsSkeleton;
