import React from "react";

const SkeletonElement = ({ className }) => (
  <div className={`${className} animate-pulse bg-gray-300 rounded`}></div>
);

const ResultsCompaniesIPOSkeleton = () => {
  return (
    <div className="container-width grid grid-cols-1 lg:grid-cols-3 gap-2">
      {/* Results Section Skeleton */}
      <div className="w-full bg-white shadow rounded px-2 py-2">
        <SkeletonElement className="h-6 w-3/4 my-2" />
        <div className="space-y-4 pb-4">
          {Array.from({ length: 7 }).map((_, index) => (
            <div key={index} className="mb-2">
              {index === 0 && (
                <SkeletonElement className="w-full h-48 rounded mb-2" />
              )}
              <SkeletonElement className="h-3 w-1/4 mb-1" />
              <SkeletonElement className="h-4 w-full" />
            </div>
          ))}
        </div>
        <SkeletonElement className="h-4 w-1/4 ml-auto" />
      </div>

      {/* Companies Section Skeleton */}
      <div className="w-full bg-white shadow rounded px-2 py-2">
        <SkeletonElement className="h-6 w-3/4 my-2" />
        <div className="space-y-4 pb-4">
          {Array.from({ length: 7 }).map((_, index) => (
            <div key={index} className="mb-2">
              {index === 0 && (
                <SkeletonElement className="w-full h-48 rounded mb-2" />
              )}
              <SkeletonElement className="h-3 w-1/4 mb-1" />
              <SkeletonElement className="h-4 w-full" />
            </div>
          ))}
        </div>
        <SkeletonElement className="h-4 w-1/4 ml-auto" />
      </div>

      {/* IPO Section Skeleton */}
      <div className="w-full bg-white shadow rounded px-2 py-2">
        <SkeletonElement className="h-6 w-3/4 my-2" />
        <div className="space-y-4 pb-4">
          {Array.from({ length: 7 }).map((_, index) => (
            <div key={index} className="mb-2">
              {index === 0 && (
                <SkeletonElement className="w-full h-48 rounded mb-2" />
              )}
              <SkeletonElement className="h-3 w-1/4 mb-1" />
              <SkeletonElement className="h-4 w-full" />
            </div>
          ))}
        </div>
        <SkeletonElement className="h-4 w-1/4 ml-auto" />
      </div>
    </div>
  );
};

export default ResultsCompaniesIPOSkeleton;
