import React, { useEffect, useState } from "react";
import { Skeleton, Typography, Pagination } from "@mui/material";
import { toast } from "react-toastify";
import StockCard from "./StockInAction/StockCard";
import CustomChip from "./CustomChip";

const apiHost = process.env.REACT_APP_BACKEND_URL;
const PAGE_SIZE = 9;

const StockInAction = () => {
  const [totalNews, setTotalNews] = useState(null);
  const [cards, setCards] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageCount, setPageCount] = useState(0);
  const [filter, setFilter] = useState("");
  const [loading, setLoading] = useState(false);
  const [filterLoader, setFilterLoader] = useState(false);

  useEffect(() => {
    fetchData();
  }, [currentPage, filter]);

  const fetchData = async () => {
    setLoading(true);
    try {
      let apiUrl = `${apiHost}/api/stock-in-action?page=${currentPage}&page_size=${PAGE_SIZE}&ordering=${
        filter === "gain"
          ? "-percentage"
          : filter === "loss"
          ? "percentage"
          : ""
      }`;

      // Add filter to the API URL if a filter is selected
      if (filter) {
        if (filter === "good" || filter === "bad") {
          apiUrl = `${apiHost}/api/stock-in-action?news_type=${filter} news&page=${currentPage}&page_size=${PAGE_SIZE}`;
        } else {
          apiUrl += `&stock_type=${filter}`;
        }
      }

      const authToken = JSON.parse(localStorage.getItem("Token"));
      const headers = { "Content-Type": "application/json" };
      if (authToken) headers["Authorization"] = `Bearer ${authToken}`;

      const response = await fetch(apiUrl, { method: "GET", headers });
      const data = await response.json();

      setTotalNews(data?.count);
      setPageCount(Math.ceil(data?.count / PAGE_SIZE));

      const cardsData = data.results.map((item) => ({
        title: item.company_name,
        price: `₹${item.price}`,
        profit: item.stock_type === "gain" ? item.percentage : null,
        loss: item.stock_type === "loss" ? item.percentage : null,
        news: item.news.summary,
        link: item.news.link,
        symbol: item.symbol,
        inWatchlist: item.added_in_watchlist,
      }));

      setCards(cardsData);
    } catch (error) {
      console.error("Error fetching data:", error);
      toast.error("Unable to fetch Stock in news at the moment!");
    } finally {
      setLoading(false);
      setFilterLoader(false);
    }
  };

  const handlePageChange = (event, value) => {
    setCurrentPage(value);
  };

  const handleChipClick = (selectedFilter) => {
    setCurrentPage(1);
    setFilter(selectedFilter);
    setFilterLoader(true);
  };

  return (
    <div className="container-width pt-2 grid grid-cols-1 lg:grid-cols-3 gap-2">
      <div className="col-span-2 bg-white shadow rounded px-2 py-2">
        <p className="text-lg font-bold my-2 font-merriweather border-b-2 border-gray-400">
          STOCK IN ACTION
        </p>

        <div className="chip-container flex gap-2 mt-4">
          <CustomChip
            title="All"
            selected={filter === ""}
            onClick={() => handleChipClick("")}
          />
          <CustomChip
            title="Gains"
            selected={filter === "gain"}
            onClick={() => handleChipClick("gain")}
          />
          <CustomChip
            title="Losses"
            selected={filter === "loss"}
            onClick={() => handleChipClick("loss")}
          />
          <CustomChip
            title="Good News"
            selected={filter === "good"}
            onClick={() => handleChipClick("good")}
            smallText="AI"
          />
          <CustomChip
            title="Bad News"
            selected={filter === "bad"}
            onClick={() => handleChipClick("bad")}
            smallText="AI"
          />
        </div>

        {/* Set a fixed height for the card container */}
        <div
          className="card-container mt-4 flex gap-3 flex-wrap"
          // style={{ minHeight: "600px" }}
        >
          {filterLoader || loading ? (
            Array.from({ length: 9 }, (_, index) => (
              <Skeleton
                key={index}
                variant="rectangular"
                width={220}
                height={190} // Fixed height for skeleton
                animation="wave"
                className="rounded"
              />
            ))
          ) : cards.length > 0 ? (
            cards.map((card, index) => (
              <div key={index}>
                <StockCard data={card} />
              </div>
            ))
          ) : (
            <div className="flex justify-center items-center h-[180px] w-full">
              <Typography variant="h6" color="textSecondary">
                {filter === "good"
                  ? "No Good News Available"
                  : filter === "bad"
                  ? "No Bad News Available"
                  : "No News Available"}
              </Typography>
            </div>
          )}
        </div>

        <div className="flex justify-center mt-4">
          <Pagination
            count={pageCount}
            page={currentPage}
            onChange={handlePageChange}
            color="primary"
            showFirstButton
            showLastButton
          />
        </div>
      </div>
    </div>
  );
};

export default StockInAction;
