import React, { useEffect, useState } from "react";
import { Tabs, Tab, Pagination } from "@mui/material";
import Skeleton from "@mui/material/Skeleton";

const PAGE_SIZE = 6;

const ShockerPortal = () => {
  const [selectedPriceTab, setSelectedPriceTab] = useState("Group A");
  const [selectedVolumeTab, setSelectedVolumeTab] = useState("Group A");

  // Price Shockers states
  const [priceRowData, setPriceRowData] = useState([]);
  const [priceCurrentPage, setPriceCurrentPage] = useState(1);
  const [priceTotalPages, setPriceTotalPages] = useState(1);
  const [loadingPrice, setLoadingPrice] = useState(false);

  // Volume Shockers states
  const [volumeRowData, setVolumeRowData] = useState([]);
  const [volumeCurrentPage, setVolumeCurrentPage] = useState(1);
  const [volumeTotalPages, setVolumeTotalPages] = useState(1);
  const [loadingVolume, setLoadingVolume] = useState(false);

  const [loadingDelivery, setLoadingDelivery] = useState(false);
  const [deliveryData, setDeliveryData] = useState([]);
  const [currentDeliveryPage, setCurrentDeliveryPage] = useState(1);

  const tempToken = localStorage.getItem("TempToken");

  const handlePriceTabChange = (event, newValue) => {
    setSelectedPriceTab(newValue);
    setPriceCurrentPage(1); // Reset to page 1 when changing tabs
  };

  const handleVolumeTabChange = (event, newValue) => {
    setSelectedVolumeTab(newValue);
    setVolumeCurrentPage(1); // Reset to page 1 when changing tabs
  };

  useEffect(() => {
    fetchPriceData();
  }, [selectedPriceTab, priceCurrentPage]);

  useEffect(() => {
    fetchVolumeData();
  }, [selectedVolumeTab, volumeCurrentPage]);

  useEffect(() => {
    fetchDeliveryData();
  }, [currentDeliveryPage]);

  const fetchPriceData = async () => {
    setLoadingPrice(true);
    const watchlistToken =
      tempToken || JSON.parse(localStorage.getItem("WatchlistToken"));
    const groupValue = getGroupValue(selectedPriceTab);
    const apiUrl = `https://stock.accordwebservices.com/BSEStock/GetPriceShockers?ExCode=BE49H6S&Group=${groupValue}&SectorCode=&AvgValueDay=3&PerChngValue=&Price=&IndexCode=&Top=&PageNo=${priceCurrentPage}&Pagesize=${PAGE_SIZE}&SortExpression=&SortDirection=Asc&token=${watchlistToken}`;

    try {
      const response = await fetch(apiUrl);
      if (response.ok) {
        const data = await response.json();
        // console.log("Price Shockers", data);
        setPriceTotalPages(Math.ceil(data.Table1[0].TotalRows / PAGE_SIZE));
        setPriceRowData(
          data.Table.map((stock) => ({
            company: stock.S_NAME,
            lastPrice: stock.LastPrice,
            volume: stock.Volume,
          }))
        );
      }
      setLoadingPrice(false);
    } catch (error) {
      setLoadingPrice(false);
      console.error("Error fetching price data:", error);
    }
  };

  const fetchVolumeData = async () => {
    setLoadingVolume(true);
    const watchlistToken =
      tempToken || JSON.parse(localStorage.getItem("WatchlistToken"));
    const groupValue = getGroupValue(selectedVolumeTab);
    const apiUrl = `https://stock.accordwebservices.com/BSEStock/GetVolumeShockers?ExCode=BE49H6S&Group=${groupValue}&SectorCode=&AvgVolumeDay=&PerChngVolume=&Volume=&Price=&IndexCode=&Top=&PageNo=${volumeCurrentPage}&Pagesize=${PAGE_SIZE}&SortExpression=&SortDirection=Asc&token=${watchlistToken}`;

    try {
      const response = await fetch(apiUrl);
      if (response.ok) {
        const data = await response.json();
        // console.log("Volume Shockers", data);
        setVolumeTotalPages(Math.ceil(data.Table1[0].TotalRows / PAGE_SIZE));
        setVolumeRowData(
          data.Table.map((stock) => ({
            company: stock.S_NAME,
            lastPrice: stock.LASTPRICE,
            volume: stock.LASTVOLUME,
          }))
        );
      }
      setLoadingVolume(false);
    } catch (error) {
      setLoadingVolume(false);
      console.error("Error fetching volume data:", error);
    }
  };

  const fetchDeliveryData = async () => {
    setLoadingDelivery(true);
    const watchlistToken =
      tempToken || JSON.parse(localStorage.getItem("WatchlistToken"));
    const apiUrl = `https://stock.accordwebservices.com/BSEStock/GetDeliveryShockersData?ExCode=BE49H6S&token=${watchlistToken}`;
    try {
      const response = await fetch(apiUrl);
      if (response.ok) {
        const data = await response.json();
        setDeliveryData(data.Table);
      }
      setLoadingDelivery(false);
    } catch (error) {
      setLoadingDelivery(false);
      console.error("Error fetching data:", error);
    }
  };

  const handlePricePageChange = (event, value) => {
    setPriceCurrentPage(value);
  };

  const handleVolumePageChange = (event, value) => {
    setVolumeCurrentPage(value);
  };

  const getGroupValue = (selectedTab) => {
    return selectedTab.substring(6);
  };

  return (
    <div className="container-width pt-2 grid grid-cols-1 lg:grid-cols-3 gap-2">
      <div className="w-full bg-white shadow rounded px-2 py-2">
        <p className="text-lg font-bold my-2 font-merriweather border-b-2 border-gray-400">
          PRICE SHOCKERS
        </p>
        <div className="mt-2">
          <Tabs
            value={selectedPriceTab}
            onChange={handlePriceTabChange}
            sx={{
              "& .MuiTabs-indicator": {
                height: "4px",
                borderRadius: "15px 15px 0px 0px",
              },
            }}
            variant="scrollable"
            scrollButtons
            allowScrollButtonsMobile
          >
            {["Group A", "Group B", "Group T", "Group Z"].map((tabLabel) => (
              <Tab
                key={tabLabel}
                label={tabLabel}
                value={tabLabel}
                sx={{
                  fontWeight: selectedPriceTab === tabLabel ? "bold" : "normal",
                  padding: "1px",
                  borderBottomLeftRadius: "10px",
                  borderBottomRightRadius: "10px",
                  fontFamily: "Merriweather",
                }}
              />
            ))}
          </Tabs>

          <div
            className="overflow-auto mt-3"
            style={{ height: "310px", maxWidth: "100%" }}
          >
            <table className="w-full table-fixed">
              <thead className="bg-primaryHovered sticky top-0">
                <tr className="text-sm font-bold font-merriweather text-white">
                  <th className="w-1/3 px-3 py-1 text-left tracking-wider">
                    Company Name
                  </th>
                  <th className="w-1/4 px-3 py-1 text-left tracking-wider">
                    Last Price(₹)
                  </th>
                  <th className="w-1/4 px-3 py-1 text-left tracking-wider">
                    Volume
                  </th>
                </tr>
              </thead>
              {loadingPrice ? (
                <tbody>
                  {Array.from(new Array(6)).map((_, index) => (
                    <tr key={index}>
                      <td className="px-3 py-2">
                        <Skeleton width="80%" />
                      </td>
                      <td className="px-3 py-2">
                        <Skeleton width="60%" />
                      </td>
                      <td className="px-3 py-2">
                        <Skeleton width="60%" />
                      </td>
                    </tr>
                  ))}
                </tbody>
              ) : (
                <tbody className="bg-white divide-y divide-gray-200">
                  {priceRowData.length > 0 ? (
                    priceRowData.map((row, index) => (
                      <tr
                        key={index}
                        className="text-sm font-bold font-merriweather"
                      >
                        <td className="px-3 py-2 whitespace-normal overflow-hidden overflow-ellipsis ">
                          {row.company}
                        </td>
                        <td
                          className={`px-3 py-2 whitespace-nowrap overflow-hidden overflow-ellipsis ${
                            row.lastPrice > 0
                              ? "text-[#329060]"
                              : "text-[#B91C1B]"
                          }`}
                        >
                          {row.lastPrice}
                        </td>
                        <td className="px-3 py-2 whitespace-nowrap overflow-hidden overflow-ellipsis ">
                          {row.volume}
                        </td>
                      </tr>
                    ))
                  ) : (
                    <tr>
                      <td
                        colSpan="3"
                        className="px-3 py-2 whitespace-nowrap text-center "
                      >
                        No data available
                      </td>
                    </tr>
                  )}
                </tbody>
              )}
            </table>
          </div>

          <div className="flex justify-center mt-4">
            <Pagination
              count={priceTotalPages}
              page={priceCurrentPage}
              onChange={handlePricePageChange}
              color="primary"
              sx={{
                "& .MuiPagination-ul": {
                  flexWrap: "nowrap",
                },
                "& .MuiPaginationItem-root": {
                  margin: 0,
                  minWidth: "auto",
                },
              }}
            />
          </div>
        </div>
      </div>
      <div className="w-full bg-white shadow rounded px-2 py-2">
        <p className="text-lg font-bold my-2 font-merriweather border-b-2 border-gray-400">
          VOLUME SHOCKERS
        </p>
        <div className="mt-2">
          <Tabs
            value={selectedVolumeTab}
            onChange={handleVolumeTabChange}
            sx={{
              "& .MuiTabs-indicator": {
                height: "4px",
                borderRadius: "15px 15px 0px 0px",
              },
            }}
            variant="scrollable"
            scrollButtons
            allowScrollButtonsMobile
          >
            {["Group A", "Group B", "Group T", "Group Z"].map((tabLabel) => (
              <Tab
                key={tabLabel}
                label={tabLabel}
                value={tabLabel}
                sx={{
                  fontWeight:
                    selectedVolumeTab === tabLabel ? "bold" : "normal",
                  padding: "1px",
                  borderBottomLeftRadius: "10px",
                  borderBottomRightRadius: "10px",
                  fontFamily: "Merriweather",
                }}
              />
            ))}
          </Tabs>

          <div
            className="overflow-auto mt-3"
            style={{ height: "310px", maxWidth: "100%" }}
          >
            <table className="w-full table-fixed ">
              <thead className="bg-primaryHovered sticky top-0">
                <tr className="text-sm font-bold font-merriweather text-white">
                  <th className="w-1/3 px-3 py-1 text-left tracking-wider">
                    Company Name
                  </th>
                  <th className="w-1/4 px-3 py-1 text-left tracking-wider">
                    Last Price(₹)
                  </th>
                  <th className="w-1/4 px-3 py-1 text-left tracking-wider">
                    Volume
                  </th>
                </tr>
              </thead>
              {loadingVolume ? (
                <tbody>
                  {Array.from(new Array(6)).map((_, index) => (
                    <tr key={index}>
                      <td className="px-3 py-2">
                        <Skeleton width="80%" />
                      </td>
                      <td className="px-3 py-2">
                        <Skeleton width="60%" />
                      </td>
                      <td className="px-3 py-2">
                        <Skeleton width="60%" />
                      </td>
                    </tr>
                  ))}
                </tbody>
              ) : (
                <tbody className="bg-white divide-y divide-gray-200">
                  {volumeRowData.length > 0 ? (
                    volumeRowData.map((row, index) => (
                      <tr
                        key={index}
                        className="text-sm font-bold font-merriweather"
                      >
                        <td className="px-3 py-2 whitespace-normal overflow-hidden overflow-ellipsis  ">
                          {row.company}
                        </td>
                        <td
                          className={`px-3 py-2 whitespace-nowrap overflow-hidden overflow-ellipsis ${
                            row.lastPrice > 0
                              ? "text-[#329060]"
                              : "text-[#B91C1B]"
                          }`}
                        >
                          {row.lastPrice}
                        </td>
                        <td className="px-3 py-2 whitespace-nowrap overflow-hidden overflow-ellipsis ">
                          {row.volume}
                        </td>
                      </tr>
                    ))
                  ) : (
                    <tr>
                      <td
                        colSpan="3"
                        className="px-3 py-2 whitespace-nowrap text-center "
                      >
                        No data available
                      </td>
                    </tr>
                  )}
                </tbody>
              )}
            </table>
          </div>

          <div className="flex justify-center mt-4">
            <Pagination
              count={volumeTotalPages}
              page={volumeCurrentPage}
              onChange={handleVolumePageChange}
              color="primary"
              sx={{
                "& .MuiPagination-ul": {
                  flexWrap: "nowrap",
                },
                "& .MuiPaginationItem-root": {
                  margin: 0,
                  minWidth: "auto",
                },
              }}
            />
          </div>
        </div>
      </div>
      <div className="w-full bg-white shadow rounded px-2 py-2">
        <p className="text-lg font-bold my-2 font-merriweather border-b-2 border-gray-400">
          DELIVERY SHOCKERS
        </p>
        <div className="mt-2">
          <div
            className="overflow-auto mt-3"
            style={{ height: "390px", maxWidth: "100%" }}
          >
            <table className="w-full table-fixed ">
              <thead className="bg-primaryHovered sticky top-0">
                <tr className="text-sm font-bold font-merriweather text-white">
                  <th className="w-1/3 px-3 py-1 text-left tracking-wider">
                    Company Name
                  </th>
                  <th className="w-1/4 px-3 py-1 text-left tracking-wider">
                    Current LTP
                  </th>
                  <th className="w-1/4 px-3 py-1 text-left tracking-wider">
                    Change
                  </th>
                </tr>
              </thead>
              <tbody className="bg-white divide-y divide-gray-200">
                {loadingDelivery ? (
                  Array.from(new Array(6)).map((_, index) => (
                    <tr key={index}>
                      <td className="px-3 py-2">
                        <Skeleton width="80%" />
                      </td>
                      <td className="px-3 py-2">
                        <Skeleton width="60%" />
                      </td>
                      <td className="px-3 py-2">
                        <Skeleton width="60%" />
                      </td>
                    </tr>
                  ))
                ) : deliveryData.length > 0 ? (
                  deliveryData.map((item, index) => (
                    <tr
                      key={index}
                      className="text-sm font-bold font-merriweather"
                    >
                      <td className="px-3 py-2 whitespace-normal overflow-hidden overflow-ellipsis  ">
                        {item.S_Name}
                      </td>
                      <td
                        className={`px-3 py-2 whitespace-nowrap overflow-hidden overflow-ellipsis ${
                          item.CurrentLTP > 0
                            ? "text-[#329060]"
                            : "text-[#B91C1B]"
                        }`}
                      >
                        {item.CurrentLTP}
                      </td>
                      <td className="px-3 py-2 whitespace-nowrap overflow-hidden overflow-ellipsis ">
                        {item.DELVVOLUMEChange}
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td
                      colSpan="4"
                      className="px-3 py-2 whitespace-nowrap text-center "
                    >
                      No data available
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ShockerPortal;
