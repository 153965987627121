import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { fetchRelatedNews } from "./api";
import { useNewsTabs } from "./hooks";

const IndustrySection = () => {
  const navigate = useNavigate();
  const { newsTabs } = useNewsTabs();
  const [industryNews, setIndustryNews] = useState([]);
  const [currentSlide, setCurrentSlide] = useState(0);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (newsTabs.length) {
      const industryTab = newsTabs.find((tab) => tab.label === "Industry");
      if (industryTab) {
        setLoading(true);
        fetchRelatedNews(industryTab.id, setIndustryNews).finally(() =>
          setLoading(false)
        );
      }
    }
  }, [newsTabs]);

  const handleNext = () => {
    setCurrentSlide((prev) => (prev + 4 < industryNews.length ? prev + 4 : 0));
  };

  const handlePrev = () => {
    setCurrentSlide((prev) =>
      prev - 4 >= 0
        ? prev - 4
        : industryNews.length - (industryNews.length % 4 || 4)
    );
  };

  const handleViewMore = () => {
    navigate(`/news-portal/industry`, {
      state: { news: industryNews, newLabel: "Industry" },
    });
  };

  return (
    <div className="px-2 py-2 bg-white rounded relative w-full">
      <p className="text-lg font-bold my-2 font-merriweather border-b-2 border-gray-400">
        INDUSTRY
      </p>
      <div className="relative">
        <div className="overflow-hidden">
          <div
            className="flex transition-transform duration-300"
            style={{
              transform: `translateX(-${(currentSlide * 100) / 4}%)`,
            }}
          >
            {Array.isArray(industryNews) &&
              industryNews.map((newsItem, index) => (
                <div
                  key={index}
                  className="inline-flex min-w-[24%] max-w-[24%] p-2 bg-[#fff4f0] rounded shadow hover:shadow-lg mx-1"
                  onClick={() => navigate(`/news-portal/news/${newsItem.id}`)}
                  style={{ cursor: "pointer" }}
                >
                  <div className="flex items-center space-x-2">
                    <div className="w-30 h-28 aspect-square">
                      {loading ? (
                        <div className="w-full h-full bg-gray-300 animate-pulse rounded"></div>
                      ) : (
                        <img
                          src={newsItem.image}
                          alt={newsItem.title}
                          className={`w-full h-full object-cover rounded transition-opacity duration-700 
                            ${loading ? "opacity-0" : "opacity-100"}`}
                          onLoad={() => setLoading(false)}
                          loading="lazy"
                          style={{ transition: "opacity 0.5s ease-in-out" }}
                        />
                      )}
                    </div>
                    <div className="flex-grow">
                      <p className="text-sm text-gray-500 font-merriweather">
                        {newsItem.category}
                      </p>
                      <p
                        className="text-sm font-bold hover:underline font-merriweather pb-1"
                        style={{
                          display: "-webkit-box",
                          WebkitLineClamp: 3,
                          WebkitBoxOrient: "vertical",
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                        }}
                      >
                        {newsItem.title}
                      </p>
                    </div>
                  </div>
                </div>
              ))}
          </div>
        </div>

        <button
          onClick={handlePrev}
          className="absolute left-0 top-1/2 transform -translate-y-1/2 bg-white border border-gray-300 rounded-full shadow-lg hover:bg-gray-100 flex justify-center items-center"
          style={{ width: "35px", height: "40px" }}
        >
          <span className="text-black text-3xl">&#8249;</span>
        </button>

        <button
          onClick={handleNext}
          className="absolute right-0 top-1/2 transform -translate-y-1/2 bg-white border border-gray-300 rounded-full shadow-lg hover:bg-gray-100 flex justify-center items-center"
          style={{ width: "35px", height: "40px" }}
        >
          <span className="text-black text-3xl">&#8250;</span>
        </button>
      </div>

      <div className="flex justify-end">
        <button
          className="text-blue-500 text-sm font-merriweather hover:underline"
          onClick={handleViewMore}
        >
          View More
        </button>
      </div>
    </div>
  );
};

export default IndustrySection;
