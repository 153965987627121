import React, { useEffect, useState } from "react";
import {
  Modal,
  Box,
  TextField,
  Select,
  MenuItem,
  Autocomplete,
  Radio,
  RadioGroup,
  FormControlLabel,
  Checkbox,
} from "@mui/material";
import { symbolOptions } from "./Symbol";
import axios from "axios";
import { toast } from "react-toastify";
import { useTheme } from "styled-components";
const apiHost = process.env.REACT_APP_BACKEND_URL;

function NewAlert({ edit, data, open, close, getFunc, type }) {
  const theme = useTheme();
  const label = { inputProps: { "aria-label": "Switch demo" } };
  const [newAlert, setNewAlert] = useState({
    alert_name: "",
    symbol: "",
    watchlist: false,
    prop: "Gains",
    percentage: "",
    news_alert: "",
    one_time_mail_sent: false,
    is_news_alert_recurring: true,
    alert_type: "recurring",
  });

  useEffect(() => {
    setNewAlert({
      ...newAlert,
      alert_name: edit ? data?.alert_name : "",
      symbol: edit ? data?.symbol : "",
      watchlist: edit ? data.watchlist : false,
      prop: edit ? data?.prop : "Gains",
      percentage: edit ? data?.percentage : "",
      one_time_mail_sent: edit ? data?.one_time_mail_sent : false,
    });
  }, [edit]);

  const createNewAlert = async () => {
    const config = {
      headers: {
        Authorization: `Bearer ${JSON.parse(localStorage.getItem("Token"))}`,
      },
    };

    if (newAlert.watchlist !== "false") {
      setNewAlert({ ...newAlert, watchlist: true });
    }

    let payload = {
      alert_name: newAlert.alert_name,
      alert_type: newAlert.alert_type,
      prop: newAlert.prop,
      watchlist: newAlert.watchlist,
    };

    if (newAlert.symbol) payload.symbol = newAlert.symbol;
    if (newAlert.news_alert) {
      payload.news_alert = newAlert.news_alert;
      payload.is_news_alert_recurring = newAlert.is_news_alert_recurring; // Adding the alertType for News Alerts.
    } else if (newAlert.percentage) {
      payload.percentage = parseInt(newAlert.percentage);
    }

    await axios
      .post(apiHost + "/api/stock-alert/", payload, config)
      .then((res) => {
        if (res?.status === 201) {
          toast.success("Alert created successfully");
          getFunc();
        }
      })
      .catch((err) => {
        toast.warn("Something went wrong");
        console.log(err);
      });
  };

  const updateWatchlist = () => {
    if (newAlert.watchlist !== false || newAlert.watchlist != "false") {
      setNewAlert((prevState) => ({
        ...prevState,
        watchlist: true,
      }));
    } else {
      setNewAlert((prevState) => ({
        ...prevState,
        watchlist: false,
      }));
    }
  };

  const editAlert = async () => {
    updateWatchlist();

    if (!newAlert?.percentage && !newAlert?.news_alert) {
      toast.error("Please provide a news alert");
      return;
    }

    let changes = {};
    // if (newAlert?.news_alert != data?.news_alert) {
    //   changes = { ...changes, news_alert: newAlert?.news_alert };
    // }
    if (newAlert?.alert_name != data?.alert_name) {
      changes = { ...changes, alert_name: newAlert?.alert_name };
    }
    if (newAlert?.symbol != data?.symbol) {
      changes = { ...changes, symbol: newAlert?.symbol };
    }
    if (newAlert?.watchlist != data?.watchlist) {
      changes = { ...changes, watchlist: newAlert?.watchlist };
    }
    if (newAlert?.prop != data?.prop) {
      changes = { ...changes, prop: newAlert?.prop };
    }
    if (newAlert?.percentage != data?.percentage) {
      changes = { ...changes, percentage: newAlert?.percentage };
    }
    if (newAlert?.one_time_mail_sent != data?.one_time_mail_sent) {
      changes = {
        ...changes,
        one_time_mail_sent: newAlert?.one_time_mail_sent,
      };
    }
    if (newAlert?.is_news_alert_recurring != data?.is_news_alert_recurring) {
      changes = {
        ...changes,
        is_news_alert_recurring: newAlert?.is_news_alert_recurring,
      };
    }
    if (!newAlert.news_alert && newAlert.percentage !== data?.percentage) {
      changes.percentage = parseInt(newAlert.percentage);
    }
    if (newAlert?.alert_type != data?.alert_type) {
      changes = { ...changes, alert_type: newAlert?.alert_type };
    }

    await axios
      .patch(apiHost + `/api/stock-alert/${data?.id}/`, changes, {
        headers: {
          Authorization: `Bearer ${JSON.parse(localStorage.getItem("Token"))}`,
        },
      })
      .then((res) => {
        if (res?.status === 200) {
          toast.success("Alert updated successfully");
          getFunc();
          close();
        }
      })
      .catch((err) => {
        toast.warn("something went wrong");
        console.log(err);
      });
  };
  // console.log(newAlert.news_alert);

  const onClickCreate = () => {
    updateWatchlist();
    createNewAlert();
    setNewAlert({
      ...newAlert,
      alert_name: "",
      symbol: "",
      watchlist: false,
      percentage: "",
      prop: "Gains",
      one_time_mail_sent: false,
    });
    close();
  };

  const handleNewsAlertChange = (e) => {
    setNewAlert({ ...newAlert, news_alert: e.target.value });
  };
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "80%",
    maxWidth: "450px",
    bgcolor: "background.paper",
    boxShadow: 24,
    py: 2,
    borderRadius: "20px",
  };

  const dropStyle = {
    width: "100%",
    height: "56px",
    border: "1px solid #DDDDDD",
    broderRadius: "10px",
    paddingLeft: "5px",
    outline: "1px solid rgba(59, 59, 59, 0.3)",
  };

  const handleAlertTypeChange = (index, value) => {
    setNewAlert((prevAlerts) => {
      const updatedAlerts = [...prevAlerts];
      updatedAlerts[index] = {
        ...updatedAlerts[index],
        is_news_alert_recurring: value === "true", // Convert value to boolean
      };
      return updatedAlerts;
    });
  };

  // console.log(newAlert.is_news_alert_recurring);

  return (
    <div className="overflow-auto">
      {type === "news" && (
        <Modal
          open={open}
          onClose={close}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style}>
            <div className="flex px-3 justify-between  font-semibold text-secondaryPurple">
              <span className="">
                {edit ? "Edit News Alert" : "Create News Alert"}
              </span>

              <span className="d-flex cursor-pointer" onClick={close}>
                {" "}
                X{" "}
              </span>
            </div>
            <hr className="mt-2 mb-6" />

            <div className="flex flex-col justify-center gap-4 p-4">
              <div className="flex items-center">
                <TextField
                  value={newAlert?.alert_name}
                  onChange={(e) => {
                    setNewAlert({ ...newAlert, alert_name: e.target.value });
                  }}
                  fullWidth
                  id="outlined-basic"
                  label="Alert Name"
                  variant="outlined"
                  sx={{
                    "& .MuiOutlinedInput-root": {
                      borderRadius: "10px",
                      width: "100%",
                      maxWidth: "400px",
                    },
                  }}
                />
              </div>

              <div className=" gap-3">
                <div className="">
                  <Autocomplete
                    key={newAlert.watchlist}
                    disabled={newAlert?.watchlist == "true"}
                    options={symbolOptions}
                    value={newAlert.symbol || null}
                    onChange={(e, value) => {
                      setNewAlert((prevState) => ({
                        ...prevState,
                        symbol: value,
                      }));
                    }}
                    renderInput={(params) => (
                      <TextField
                        sx={{
                          "& .MuiOutlinedInput-root": {
                            borderRadius: "10px",
                            width: "100%",
                            maxWidth: "400px",
                          },
                        }}
                        {...params}
                        label="Choose a stock"
                      />
                    )}
                  />
                </div>
              </div>
              <div className="d-flex  flex-column gap-2">
                <Select
                  color="primary"
                  style={{
                    width: "100%",
                    maxWidth: "400px",
                    borderRadius: "10px",
                  }}
                  displayEmpty
                  value={newAlert.news_alert}
                  onChange={handleNewsAlertChange}
                >
                  <MenuItem value={""}>Choose an Alert</MenuItem>
                  <MenuItem value={"all news"}>All News</MenuItem>
                  <MenuItem value={"corporate news"}>Corporate News</MenuItem>
                </Select>
              </div>
              <div className="d-flex">
                <div className="d-flex flex-column gap-2">
                  <span className="h6">Alert Type</span>
                  <div className="d-flex align-items-center gap-2">
                    <RadioGroup
                      row
                      aria-label="alert-type"
                      name="alertType"
                      value={newAlert.is_news_alert_recurring}
                      onChange={(e) => {
                        setNewAlert({
                          ...newAlert,
                          is_news_alert_recurring: e.target.value,
                        });
                      }}
                    >
                      <FormControlLabel
                        value="true"
                        control={<Radio />}
                        label="Instantly"
                      />
                      <FormControlLabel
                        value="false"
                        control={<Radio />}
                        label="EOD"
                      />
                    </RadioGroup>
                    <div className="w-48"></div>
                  </div>
                </div>
              </div>
            </div>

            <hr></hr>
            <div
              className="d-flex mt-4 align-content-end px-3 "
              style={{ width: "100%", right: "0px", justifyContent: "right" }}
            >
              <div className="d-flex gap-3">
                <button
                  className="btn rounded-full px-4 bg-primaryBtn text-white hover:bg-primaryHovered"
                  onClick={() => {
                    edit ? editAlert() : onClickCreate();
                  }}
                >
                  {edit ? "Save" : "Create"}
                </button>
                <button
                  className="btn btn-outline-dark px-4 hover:text-primaryBrown text-primaryBrown rounded-full bg-secondaryBtn hover:bg-secondaryHovered"
                  onClick={close}
                >
                  Cancel
                </button>
              </div>
            </div>
          </Box>
        </Modal>
      )}

      {type === "price" && (
        <Modal
          open={open}
          onClose={close}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style}>
            <div className="d-flex justify-content-between font-semibold px-4">
              <span className="text-lg font-semibold text-secondaryPurple">
                <p className="font-semibold">
                  {edit ? "Edit Price Alert" : "Create Price Alert"}
                </p>
              </span>

              <span
                className="d-flex cursor-pointer text-primaryBrown"
                onClick={close}
              >
                {" "}
                X{" "}
              </span>
            </div>
            <hr className="mt-2 mb-6" />

            <div className="flex flex-col gap-4 px-4">
              <TextField
                value={newAlert?.alert_name}
                onChange={(e) => {
                  setNewAlert({ ...newAlert, alert_name: e.target.value });
                }}
                id="outlined-basic"
                label="Alert Name"
                variant="outlined"
                sx={{
                  "& .MuiOutlinedInput-root": {
                    borderRadius: "10px",
                    width: "100%",
                    maxWidth: "400px",
                  },
                }}
              />

              <div className=" gap-3">
                <div className="">
                  <Autocomplete
                    key={newAlert.watchlist}
                    disabled={newAlert?.watchlist == "true"}
                    options={symbolOptions}
                    value={newAlert.symbol || null}
                    onChange={(e, value) => {
                      setNewAlert((prevState) => ({
                        ...prevState,
                        symbol: value,
                      }));
                    }}
                    renderInput={(params) => (
                      <TextField
                        sx={{
                          "& .MuiOutlinedInput-root": {
                            borderRadius: "10px",
                            width: "100%",
                            maxWidth: "400px",
                          },
                        }}
                        {...params}
                        label="Choose a stock"
                      />
                    )}
                  />
                </div>
              </div>
              <span className="h6 text-center w-full">OR</span>

              <div className="flex gap-2 justify-center flex-wrap ">
                <TextField
                  select
                  disabled={newAlert.symbol}
                  value={newAlert.watchlist ? "true" : "false"}
                  onChange={(e) => {
                    setNewAlert({
                      ...newAlert,
                      watchlist: e.target.value === "true",
                    });
                  }}
                  sx={{
                    "& .MuiOutlinedInput-root": {
                      borderRadius: "10px",
                      width: "100%",
                      maxWidth: "200px",
                    },
                  }}
                >
                  <MenuItem value={"false"}>Choose a Group</MenuItem>
                  <MenuItem value={"true"}>WatchList</MenuItem>
                </TextField>
                <div className="flex  justify-start gap-2">
                  <div className=" flex w-[200px] rounded-lg border border-gray-800">
                    <Select
                      value={newAlert.prop}
                      displayEmpty
                      onChange={(e) => {
                        setNewAlert({ ...newAlert, prop: e.target.value });
                      }}
                      sx={{
                        boxShadow: "none",
                        ".MuiOutlinedInput-notchedOutline": {
                          border: 0,
                          borderRadius: "10px",
                          width: "100%",
                          maxWidth: "200px",
                        },
                        "&.MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline":
                          {
                            border: 0,
                          },
                        "&.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
                          {
                            border: 0,
                          },
                      }}
                    >
                      <MenuItem value="Gains">Gains %</MenuItem>
                      <MenuItem value="Losses">Losses %</MenuItem>
                    </Select>
                    <TextField
                      variant="standard"
                      margin="normal"
                      type="number"
                      placeholder="3"
                      sx={{
                        "& .MuiOutlinedInput-root": {
                          borderRadius: "10px",
                          width: "100%",
                          maxWidth: "220px",
                        },
                      }}
                      value={newAlert?.percentage}
                      onChange={(e) => {
                        setNewAlert({
                          ...newAlert,
                          percentage: e.target.value,
                        });
                      }}
                      InputProps={{
                        inputProps: {
                          min: 0,
                        },
                        disableUnderline: true,
                      }}
                      onKeyPress={(e) => {
                        if (e.key === "+" || e.key === "-") {
                          e.preventDefault();
                        }
                      }}
                    ></TextField>
                  </div>
                </div>
              </div>

              <div className="d-flex flex-column gap-2">
                <span className="h6">Alert Type</span>
                <div className="d-flex  gap-2">
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={!newAlert?.one_time_mail_sent}
                        onChange={() => {
                          setNewAlert({
                            ...newAlert,
                            one_time_mail_sent: !newAlert?.one_time_mail_sent,
                            alert_type:
                              newAlert?.alert_type === "recurring"
                                ? "one time"
                                : "recurring",
                          });
                        }}
                        sx={{
                          color: "#000",
                          "&.Mui-focused": {
                            borderColor: "#6C4E62",
                          },
                        }}
                      />
                    }
                    label="Recurring"
                  />
                  <div className="w-[250px]"></div>
                </div>
              </div>
            </div>

            <hr></hr>
            <div
              className="d-flex mt-8 align-content-end"
              style={{ width: "100%", right: "0px", justifyContent: "right" }}
            >
              <div className="d-flex gap-3 px-4 text-white">
                <button
                  className="btn rounded-full px-4 text-white bg-primaryBtn hover:bg-primaryHovered"
                  onClick={() => {
                    edit ? editAlert() : onClickCreate();
                  }}
                >
                  {edit ? "Save" : "Create"}
                </button>
                <button
                  className="btn px-4 btn-outline-dark hover:text-primaryBrown text-primaryBrown rounded-full bg-secondaryBtn hover:bg-secondaryHovered"
                  onClick={close}
                >
                  Cancel
                </button>
              </div>
            </div>
          </Box>
        </Modal>
      )}
    </div>
  );
}

export default NewAlert;
