import React, { useEffect, useState } from "react";

const tempToken = localStorage.getItem("TempToken");

const LiveIndics = () => {
  const [indicesData, setIndicesData] = useState([]);
  const [error, setError] = useState("");
  const [isLoading, setIsLoading] = useState(true);

  const fetchIndicesData = async () => {
    setIsLoading(true);
    const watchlistToken =
      tempToken || JSON.parse(localStorage.getItem("WatchlistToken"));
    const url = `https://stock.accordwebservices.com/BSEStock/GetLiveIndiceData?ExCode=BE49H6S&Top=&PageNo=1&Pagesize=10&SortExpression=PER_CHANGE&SortDirection=Desc&token=${watchlistToken}`;
    try {
      const response = await fetch(url);
      const jsonData = await response.json();
      if (jsonData && jsonData.Table && jsonData.Table.length > 0) {
        setIndicesData(jsonData.Table);
      } else {
        setError("No indices data found. Please try again later.");
      }
    } catch (error) {
      setError(`Failed to fetch indices data: ${error.message}`);
    } finally {
      setIsLoading(false);
    }
  };

  const formatIndexName = (indexName) => {
    const words = indexName.split(" ");
    if (words.length > 2) {
      return `${words[0]} ${words[1]}\n${words.slice(2).join(" ")}`;
    }
    return indexName;
  };

  useEffect(() => {
    fetchIndicesData();
  }, []);

  const SkeletonLoader = () => {
    return (
      <div className="animate-pulse">
        <div className="space-y-4">
          {[...Array(5)].map((_, i) => (
            <div key={i} className="flex items-center space-x-4">
              <div className="w-full bg-gray-300 h-4 rounded-md skeleton"></div>
            </div>
          ))}
        </div>
      </div>
    );
  };

  return (
    <div className="bg-white shadow rounded mt-2 lg:ml-2 lg:w-[97%] font-merriweather">
      {isLoading ? (
        <SkeletonLoader />
      ) : error ? (
        <p className="text-red-500">{error}</p>
      ) : (
        <>
          <div>
            <table className="min-w-full leading-normal">
              <thead>
                <tr className="font-bold">
                  <th className="px-3 py-2 border-b-2 border-gray-200 bg-gray-100 text-left text-xs font-bold text-black uppercase tracking-wider">
                    Index
                  </th>
                  <th
                    className="px-6 py-2 border-b-2 border-gray-200 bg-gray-100 text-right text-xs font-bold text-black uppercase tracking-wider"
                    style={{ pqaddingRight: "0" }}
                  >
                    Price
                  </th>
                  <th className="px-2 py-2 border-b-2 border-gray-200 bg-gray-100 text-right text-xs font-bold text-black uppercase tracking-wider">
                    Change
                  </th>
                  <th className="px-1 py-2 border-b-2 border-gray-200 bg-gray-100 text-right text-xs font-bold text-black uppercase tracking-wider">
                    % Chg
                  </th>
                </tr>
              </thead>
            </table>
          </div>
          <div className="max-h-[95px] overflow-x-auto">
            <table className="min-w-full leading-normal ">
              <tbody className="overflow-y-auto">
                {indicesData.map((item, index) => (
                  <tr key={index}>
                    <td className="px-3 py-2 border-b border-gray-200 bg-white text-xs">
                      {formatIndexName(item.INDEX_LNAME)}
                    </td>
                    <td className="px-0 py-2 border-b border-gray-200 bg-white text-xs">
                      {item.CLOSE}
                    </td>
                    <td
                      className={`px-2 py-2 border-b border-gray-200 bg-white text-xs ${
                        parseFloat(item.CHANGE) < 0 ? "text-red-500" : " text-[#198753]"
                      }`}
                    >
                      {item.CHANGE}
                    </td>
                    <td
                      className={`px-1 py-2 border-b border-gray-200 bg-white text-xs ${
                        parseFloat(item.PER_CHANGE) < 0 ? "text-red-500" : "text-[#198753]"
                      }`}
                    >
                      {item.PER_CHANGE}%
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </>
      )}
    </div>
  );
};

export default LiveIndics;
