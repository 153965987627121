import React, { useEffect, useRef, useState } from "react";
import axios from "axios";
import {
  Routes,
  Route,
  useLocation,
  useNavigate,
  Link,
} from "react-router-dom";
import NewsNavbar from "./NewsNavbar";
import NewsContainer from "../../pages/NewsPortal/NewsContainer";
import Article from "./Article";
import Sidebar from "./SideBar";
import Menu from "./Menu";
import { useNewsTabs, useGoogleAuth } from "./hooks";
import CorporateNotificationPanel from "./CorporateNotificationPanel";
import GainersAndLosersNewsPortal from "../GainersAndLosersNewsPortal";
import IndustrySection from "./IndustrySection";
import ResultsCompaniesIPOSection from "./ResultsCompaniesIPOSection";
import queryString from "query-string";
import parse from "html-react-parser";
import Skeleton from "react-loading-skeleton";
import ResultsCompaniesIPOSkeleton from "../Skeleton/ResultsCompaniesIPOSkeleton";
import NewsSkeleton from "../Skeleton/NewsSkeleton";
import AdvanceAndDeclines from "../AdvanceAndDeclines";
import LiveIndics from "../LiveIndics";
import PriceChart from "../PriceChart";
import ShockerPortal from "../ShockerPortal";
import StockInAction from "../StockInAction";
import Reports from "../Reports";

function MainNewsPortal(props) {
  const location = useLocation();
  const [value, setValue] = useState(false);
  const [newsData, setNewsData] = useState([]);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [dataFetched, setDataFetched] = useState(false);
  const [scrollPosition, setScrollPosition] = useState(0);
  const [groupType, setGroupType] = useState([]);
  const [filteredNews, setFilteredNews] = useState([]);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const [isLoaded, setIsLoaded] = useState(false);

  const { newsTabs } = useNewsTabs();
  const { googleLogin } = useGoogleAuth(props.setLogin);
  const isLoggedIn = props.login;
  const params = queryString.parse(location.search);
  const tag = params.tag;
  const [page, setPage] = useState(1);
  const [hasMore, setHasMore] = useState(true);
  const loader = useRef(null);

  const debounceDelay = 500; // Delay time in ms for debouncing

  useEffect(() => {
    // Simulate a loading delay
    const timer = setTimeout(() => {
      setIsLoaded(true);
    }, 1000); // assuming it takes 1 seconds to load the data

    return () => clearTimeout(timer);
  }, []);

  useEffect(() => {
    const observer = new IntersectionObserver(handleObserver, {
      root: null,
      rootMargin: "20px",
      threshold: 1.0,
    });
    if (loader.current) {
      observer.observe(loader.current);
    }

    return () => {
      if (loader.current) {
        observer.unobserve(loader.current);
      }
    };
  }, []);

  const handleObserver = (entities) => {
    const target = entities[0];
    if (target.isIntersecting && hasMore && !loading) {
      setLoading(true);
      setTimeout(() => {
        setPage((prevPage) => prevPage + 1);
        setLoading(false);
      }, debounceDelay); // Adding debounce delay
    }
  };

  useEffect(() => {
    fetchNews(page);
  }, [page]);

  useEffect(() => {
    if (tag && newsData.length) {
      const filtered = newsData.filter(
        (news) =>
          news.generic_tags &&
          news.generic_tags.some(
            (gt) => gt[0].toLowerCase() === tag.toLowerCase()
          )
      );
      setFilteredNews(filtered);
    }
  }, [location.search, newsData]);

  useEffect(() => {
    const token = localStorage.getItem("Token");
    if (token) {
      const decodedToken = JSON.parse(atob(token.split(".")[1]));
      if (decodedToken.exp && decodedToken.exp * 1000 < Date.now()) {
        localStorage.clear();
        props.setLogin(false);
      }
    }
  }, [props.setLogin]);

  useEffect(() => {
    const path = location.pathname.split("/").slice(0, 3).join("/");
    const index = newsTabs.findIndex((tab) => tab.path === path);
    setValue(index >= 0 ? index : false);
  }, [location.pathname, newsTabs]);

  const fetchNews = async (page) => {
    if (!hasMore) return;
    try {
      setLoading(true);
      const response = await axios.get(
        `${process.env.REACT_APP_BACKEND_URL}/api/portal-news`,
        {
          params: { page: page },
        }
      );
      const data = response.data.results;
      if (data.length > 0) {
        setNewsData((prevNewsData) => [...prevNewsData, ...data]);
      } else {
        setHasMore(false);
      }
    } catch (error) {
      console.error("Error fetching data", error);
    } finally {
      setDataFetched(true);
      setLoading(false);
    }
  };

  const logout = () => {
    localStorage.clear();
    props.setLogin(false);
  };

  const isCorporateNews =
    newsTabs[value]?.label?.toLowerCase() === "corporate news";
  const isIPONews = newsTabs[value]?.label?.toLowerCase() === "ipo";
  const isHome = location.pathname === "/news-portal/home";

  const capitalizeFirstLetter = (string) => {
    if (!string) return "";
    return string.charAt(0).toUpperCase() + string.slice(1);
  };

  const reportPath = "/news-portal/markets/report"; // Define the path that shows the report
  const [showReports, setShowReports] = useState(false);

  useEffect(() => {
    // Automatically toggle report visibility based on the current path
    setShowReports(location.pathname === reportPath);
  }, [location.pathname]);

  // Function to toggle the Reports display
  const handleReportClick = () => {
    setShowReports(true);
  };

  return (
    <>
      <div>
        <div className="w-full z-50">
          <NewsNavbar
            // onReportClick={handleReportClick}
            login={props.login}
            setLogin={props.setLogin}
          />
        </div>

        <div
          className={`${
            isIPONews
              ? "justify-center max-w-full lg:ml-[45px] lg:mr-[45px] md:ml-[110px] md:mr-[110px] ml-4 mr-4"
              : "container-width"
          } flex flex-col lg:flex-row`}
        >
          {!showReports && (
            <div className="lg:w-4/5 w-full">
              {!dataFetched ? (
                <NewsSkeleton />
              ) : tag ? (
                <div className="grid grid-cols-1 md:grid-cols-1 lg:grid-cols-1 gap-2 w-6/7 mb-5">
                  <div className="flex items-center py-0">
                    <Link
                      to="/news-portal/home"
                      className="text-blue-500 hover:text-blue-700 font-semibold transition duration-200"
                    >
                      Home
                    </Link>
                    <span className="mx-2 text-gray-700">&gt;</span>
                    <span className="text-black font-medium">
                      {capitalizeFirstLetter(tag)}
                    </span>
                  </div>
                  {filteredNews.map((news, index) => (
                    <div
                      key={index}
                      onClick={() => navigate(`/news-portal/news/${news.id}`)}
                      className="flex items-center bg-white cursor-pointer shadow-md rounded-lg overflow-hidden"
                    >
                      <div className="w-[180px] h-[120px] flex items-center justify-center bg-gray-200 overflow-hidden">
                        {news.image ? (
                          <img
                            src={news.image}
                            alt="news"
                            className="w-full h-full object-cover"
                            loading="lazy"
                          />
                        ) : (
                          <Skeleton width={180} height={120} />
                        )}
                      </div>
                      <div className="flex flex-col justify-between px-3 py-2 w-full">
                        <h3 className="text-lg font-semibold text-gray-800 line-clamp-2 font-merriweather">
                          {news.title}
                        </h3>
                        <p className="text-sm text-gray-600 mt-2 line-clamp-3 font-merriweather">
                          {parse(news.summary)}
                        </p>
                      </div>
                    </div>
                  ))}
                </div>
              ) : (
                <Routes>
                  <Route
                    path="/news-portal"
                    element={
                      <NewsContainer newsTabs={newsTabs} newsData={newsData} />
                    }
                  />
                  <Route
                    path="/news-portal/:path"
                    element={
                      <NewsContainer newsTabs={newsTabs} newsData={newsData} />
                    }
                  />
                  <Route
                    path="/news-portal/news/:newsId"
                    element={<Article login={isLoggedIn} />}
                  />
                </Routes>
              )}
            </div>
          )}
          {showReports && <Reports />}

          {isHome ? (
            <div className="flex flex-col w-full lg:w-1/3">
              <PriceChart />
              <LiveIndics />
              <AdvanceAndDeclines />
              <GainersAndLosersNewsPortal groupType={groupType} />
            </div>
          ) : isCorporateNews ? (
            <CorporateNotificationPanel />
          ) : isIPONews ? null : (
            <Sidebar newsData={newsData} />
          )}
        </div>
        {isHome && (
          <>
            <div
              className={`flex flex-col lg:flex-row ${
                isIPONews ? "justify-center" : "container-width"
              } pt-2 pb-2 lg:pt-4 lg:pb-4`}
            >
              <IndustrySection />
            </div>
            {isLoaded ? (
              <ResultsCompaniesIPOSection
                newsTabs={newsTabs}
                newsData={newsData}
              />
            ) : (
              <ResultsCompaniesIPOSkeleton />
            )}
            {isHome ? (
              <>
                <ShockerPortal />
                <StockInAction />
              </>
            ) : isIPONews ? null : (
              <></>
            )}
          </>
        )}
        <br />
      </div>
      <Menu
        login={isLoggedIn}
        logout={logout}
        setLogin={props.setLogin}
        isOpen={isMenuOpen}
        googleLogin={googleLogin}
        onClose={() => setIsMenuOpen(false)}
      />
      <div ref={loader} className="loading-ref"></div>
    </>
  );
}

export default MainNewsPortal;
