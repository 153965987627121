import React from "react";

const ForwardArrowIcon = ({fill}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
    >
      <g clipPath="url(#clip0_359_538)">
        <path
          d="M11.06 4.66669C11.0595 4.75443 11.0763 4.8414 11.1095 4.92263C11.1427 5.00385 11.1915 5.07773 11.2533 5.14002L18.1133 12L11.2533 18.86C11.1441 18.9876 11.087 19.1516 11.0935 19.3194C11.1 19.4872 11.1696 19.6463 11.2883 19.7651C11.407 19.8838 11.5662 19.9533 11.734 19.9598C11.9017 19.9663 12.0658 19.9092 12.1933 19.8L20 12L12.1933 4.19336C12.0997 4.10162 11.9812 4.0395 11.8525 4.01476C11.7238 3.99003 11.5907 4.00377 11.4697 4.05428C11.3488 4.10478 11.2454 4.18981 11.1726 4.29873C11.0997 4.40765 11.0605 4.53564 11.06 4.66669Z"
          fill={fill ? fill : "#F8F7F8"}
        />
        <path
          d="M3.7265 4.66669C3.726 4.75443 3.74281 4.8414 3.77599 4.92263C3.80917 5.00385 3.85805 5.07773 3.91984 5.14002L10.7798 12L3.91984 18.86C3.81062 18.9876 3.75355 19.1516 3.76003 19.3194C3.76651 19.4872 3.83606 19.6463 3.95479 19.7651C4.07352 19.8838 4.23268 19.9533 4.40047 19.9598C4.56825 19.9663 4.7323 19.9092 4.85984 19.8L12.6665 12L4.85984 4.19336C4.76625 4.10162 4.6477 4.0395 4.519 4.01476C4.3903 3.99003 4.25717 4.00377 4.13624 4.05428C4.01531 4.10478 3.91195 4.18981 3.83908 4.29873C3.7662 4.40765 3.72705 4.53564 3.7265 4.66669Z"
          fill={fill ? fill : "#F8F7F8"}
        />
      </g>
      <defs>
        <clipPath id="clip0_359_538">
          <rect
            width="24"
            height="24"
            fill="white"
            transform="matrix(0 -1 -1 0 24 24)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export default ForwardArrowIcon;
