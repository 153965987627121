import axios from "axios";
import React, { useEffect, useState } from "react";
import { apiUrl } from "../../utils/utils";

const IPODetailNewsPortalModal = ({ isOpen, onClose, ipo_name }) => {
  const [ipoData, setIpoData] = useState({});

  const fetchIpoDetails = async () => {
    try {
      const response = await axios.get(apiUrl + `/api/ipo-data/${ipo_name}`);
      setIpoData(response.data);
    } catch (error) {
      console.error("Error While fetching ipo details::", error);
    }
  };

  useEffect(() => {
    if (isOpen) {
      fetchIpoDetails();
    }
  }, [isOpen]);

  const renderSection = (sectionKey, tableData, description) => {
    if (!tableData || tableData.length === 0) return null;
    const sectionName = sectionKey.replace(/_/g, " ").toUpperCase();
    return (
      <div>
        <div className="font-bold text-darkBlue flex flex-col md:px-6 mt-8">
          <h1 className="text-2xl">{`${ipo_name} ${sectionName}`}</h1>
          {description && (
            <div className="py-4 text-md font-normal">{description}</div>
          )}
        </div>
        <div
          className="overflow-x-auto overflow-y-auto w-full md:px-8"
          style={{ maxHeight: "500px" }}
        >
          <table className="bg-white table-fixed w-full">
            <thead>
              <tr className="bg-tableDarkHeader text-white">
                {Object.keys(tableData[0]).map((key) => (
                  <th key={key} className="px-2 py-1 border">
                    {key}
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>
              {tableData.map((row, idx) => (
                <tr
                  key={idx}
                  className={idx % 2 === 0 ? "bg-tableBody" : "bg-white"}
                >
                  {Object.entries(row).map(([key, value], idx) => (
                    <td
                      key={idx}
                      className="px-2 py-1 border text-sm overflow-auto"
                    >
                      {value}
                    </td>
                  ))}
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    );
  };

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
      <div className="bg-white rounded-lg shadow-lg w-full md:w-3/4 lg:w-2/3 xl:w-1/2 overflow-auto max-h-[90vh]">
        <div className="flex flex-col pb-10 justify-between  md:px-0 mt-10 md:mt-2">
          {/* Modal Header */}
          <div className="h-16 font-bold text-darkBlue flex justify-between items-center border-b w-full px-4">
            <h1 className="text-2xl">{ipo_name}</h1>
            <button
              className="text-xl font-bold text-red-600 hover:text-red-800"
              onClick={onClose}
            >
              &times;
            </button>
          </div>
          {/* Description */}
          <div
            className="flex justify-center md:px-16 py-4 text-md font-normal"
            dangerouslySetInnerHTML={{ __html: ipoData?.description }}
          ></div>
          {/* Dynamic Sections */}
          <div>
            {Object.entries(ipoData).map(([key, value]) =>
              renderSection(key, value.table_data, value.description)
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default IPODetailNewsPortalModal;
